/* MainPage.css */
.MainContainer {
    background-color: #1c1c1c;;;  /* Set the background color */
    padding-left: 5%;             /* Add 4% padding */
    padding-right: 5%;             /* Add 4% padding */
    width: 100%;      
            /* Adjust width to maintain total 100% with padding */
    margin: 0 auto;          /* Center the container */
    box-sizing: border-box;  /* Ensure padding is included in total width/height */
  }


html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
    overflow-y: auto;
}

  @media (max-width: 767px) {

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

    .MainContainer{
      width: 90vw !important;
      box-sizing:unset !important;
      background-color: white;
      padding-left: 0%;             /* Add 4% padding */
     padding-right: 0%;  
     margin-bottom: 2%; 
    }

  html, body {
    width: 100%;
    height: 100%;
    background-color: #1c1c1c;
     padding-bottom: 2%; 
  }
  }