.blog-container {
    display: grid;
    grid-template-columns: 20% 60% 20%;  /* 3 columns: 20% for menu, 60% for content, 20% for empty space */
    background-color: #2e2e2e;
    color: white;
    margin: 0;
    padding: 0;
    font-family: 'Russo', sans-serif;
  height: auto;/* Ensure the container spans the full viewport height */
}

.menu {
    background-color: transparent;
    padding: 20px;
    display: flex;
    flex-direction: column;

}


.menu ul li .a_active {
    background-color: #f1c40f;
    color: #2e2e2e;
}

.menu ul {
    padding: 0;
    font-size: x-small;
}

.menu ul li {
    list-style-type: none;
    margin: 10px 0;
}

.menu ul li a {
    text-decoration: none;
    color: white;
    padding: 10px;
    display: block;
    background-color: rgb(106, 0, 0);
    border: 1px solid black;
    text-align: center;
    border-radius: 4px;
}

.menu ul li a:hover {
    background-color: #f1c40f;
    color: #2e2e2e;
}

.blog-content {
    background-color: #1c1c1c;
    padding: 4%;
    /*overflow-y: auto; /* Add scroll if content is too long */
}

.empty-space {
    /* Empty space for future use */
}

.bela {
    margin-top: -4.5%;
    font-size: larger;
    margin-bottom: 20px;
    color: #f1c40f;
    display: block;
  
}

p, ol {
    font-size: small;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
}

ol li {
    margin-bottom: 10px;
}

strong {
    color: #9e1000;
}

.author_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1%;
    margin-top: 20px;
}

.author-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid white;
    margin-top: -40px;
}

.author-text {
    margin-top: -40px;
    margin-left: 10px;
}

.welding-imagetop {
    width: 80%;
    padding-left: 10%;
    height: auto;
    margin-top: 20px;
    
    
}


@media screen and (max-width: 767px) {
    .bela-mobile {
        text-align: center;
        display: block;
        font-size: medium !important;
        width: 100%;
        height: auto;
        margin-top: 35%;
        margin-left: 20%;
        margin-bottom: 2%;
        background-color: black;
        color: #f1c40f;
    }

    .welding-imagetop {
        display: block;
        width: 90%;
        margin-left: -10%;
        margin-bottom: 2%;
    }

    .blog-content {
        text-align: justify; /* Sorkizárt szöveg */
        padding: 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .blog-content p, .blog-content ol {
        width: 96%;
        text-align: justify; /* Sorkizárt szöveg minden bekezdésben */
    }

    ol {
        padding-left: 5%;
    }


    .author_div {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important; /* Vertically center items */
        justify-content: flex-start !important; /* Align items to the start (left) */
        padding: 1% !important;
        margin-top: 20px !important;
        width: 100%; /* Ensure it takes full width */
    }

    .author-image {
        margin-left: 2% !important;
        width: 15% !important;
        height: auto !important;
        border-radius: 50% !important;
        border: 2px solid white !important;
        margin-top: -40px !important;
    }

    .author-text {
        margin-top: -20px !important;
        margin-left: 10px !important;
        text-align: left !important; /* Ensure text is aligned to the left */
        font-size: 14px !important; /* Set a specific font size */
        width: 80%; /* Adjust width as needed */
    }

    .author-title {
        text-align: left !important;
        font-size: small !important; /* Ensure font size is small */
        color: #555; /* Add a color for better visibility */
        margin-top: 5px; /* Add some spacing */
    }

}

