@font-face {
  font-family: Russo;
  src: url('../font/RussoOne-Regular.ttf');
  font-weight: 400;
}


/* Alapértelmezett navbar stílusok */
.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  padding: 0 20px; /* Adjust padding */
  height: 15vh;
  line-height: 80px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4) !important;
  width: 100%;
  overflow: hidden; /* Prevent elements from overflowing */
}

.navbar > * {
  flex-shrink: 1;
  max-width: calc(33.33%); /* Adjust for three elements */
  box-sizing: border-box;
}


.navbar-logo {
  display: flex;
  align-items: center;
  margin-right: 4vw;
}


.navbar-menu {
  display: flex;
  list-style: none;
}

.navbar-item {
  margin-top: 30px;
  margin-right: 2.5vw;
  justify-content: center;
  align-content: center;
}



.navbar-button:hover {
  background-color: #000;
  color: whitesmoke;
  border: 2px solid #fff;
}

.navbar-link {
  color: #fff;
  text-decoration: none;
  font-size: 1.25vw !important;
  font-family: 'Russo', sans-serif !important;
  transition: background-color 0.3s ease;
}

.navbar-link2 {
  background-color: #fff; /* White background */
  color: #000; /* Black text */
  text-decoration: none; /* Remove underline */
  font-size: 1.5vw !important; /* Keep responsive font size */
  font-family: 'Russo', sans-serif !important; /* Font family */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
  padding: 10px 20px; /* Button-like padding */
  border: 2px solid #000; /* Black border */
  border-radius: 10px; /* Rounded corners */
  display: inline-block; /* Ensure button-like behavior */
  text-align: center; /* Center the text */
  box-sizing: border-box; /* Include padding in size */
}

.navbar-link:hover {
  background-color: #000; /* Black background on hover */
  color: #fff; /* White text on hover */
}

.navbar-link:hover {
  color: #aaa;
  border-bottom: 2px solid #bbb;
}

.nav-link {
  text-decoration: none;
}

/* Hamburger ikon stílusai */

.mobile-menu-icon {
  display: block; /* Mindig jelenjen meg */
  cursor: pointer;
  position: absolute;
  right: 20px; /* Igazítás a jobb oldalra */
  top: 50%; /* Kezdőpozíció függőlegesen */
  transform: translateY(-50%); /* Középre igazítás függőlegesen */
  z-index: 101;
}

.bar {
  width: 35px;
  height: 50px;
  background-color: #fff;
  margin: 0px 0;
  transition: 0.4s;
}

.change.bar:nth-child(1) {
  transform: rotate(-45deg) translate(-9px, 6px);
}

.navbar-button-lang {
  background-color: #000;
  color: white;
  padding: 5px 7px 0px 0px;
  border: none;
  font-size: 17px;
  transition: background-color 0.3s ease;
  font-family: 'Russo', sans-serif !important;
}

.navbar-button-lang:hover{
  color: #bbb;
  border-bottom: 2px solid #bbb;
}

/* Hamburger ikon változásai kattintáskor */
.change.bar:nth-child(1) {
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change.bar:nth-child(2) {
  opacity: 0;
}

.change.bar:nth-child(3) {
  transform: rotate(45deg) translate(-9px, -6px);
}



  /* Testre szabás: hozzáadhatod az egyedi stílusokat és elhelyezkedéseket */
  .menu-item_ {
    display: fixed;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Russo', sans-serif;
    transition: background-color 0.3s ease;
  }
  
  .menu-item:hover {
    color: #555;
  }



.bm-menu {
  background-color: #FFF;
  padding: 0em 0em 0;
  font-size: 1.15em;
}

/* double*/
.bm-item-list {
  color: #FFF;
  padding: 0em;
}

.bm-item-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}

.bm-item-list a {
  color: #FFF;
  text-decoration: none;
  display: block;
  padding: 0px;
  font-family: 'Russo', sans-serif !important;
  text-align: center;
}

.bm-item-list a:hover {
  color: #aaa;
}
/* Double*/
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.hamburger{
  z-index: 9999;
}

.lang-selector-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flag-icon {
  width: 30px; /* Set the desired size */
  height: auto;
  margin: 0px; /* Space between flag and text */
  border-radius: 4px !important;
  border-width: 1px !important;
  border-color: white !important;
  border-style: solid; /* Add this line */
}
.flag-row {
  margin-bottom: 5px; /* Adjust spacing between flag and selector */
}

.flag-image {
 width: 25px; /* Set the desired size */
  height: auto;
  margin: 0px; /* Space between flag and text */
  border-radius: 4px !important;
  border-width: 1px !important;
  border-color: white !important;
  border-style: solid; /* Add this line */
}

.selector-row select {
  width: 6vw; /* Adjust width as needed */
  text-align: center;
}
.custom-dropdown-lang {
  width: 150px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid white;
  background-color: black;
  color: white;
      font-size: 1vw !important;
    font-family: 'Russo', sans-serif !important;
  appearance: none;

}

.custom-dropdown-lang option {
  width: 200px;

  padding: 5px;
      font-size: 0.75vw !important;
    font-family: 'Russo', sans-serif !important;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 20px;
  text-indent: 25px; /* Space for the image */
  height: 30px; /* Ensures proper spacing for flags */
}
/* ------------------------ Hamburgermenu.css ------------------- */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 30px;
  /* Így az ikon tükrözve jelenik meg */
  transform: scaleX(-1);
  left: auto;
  right: 40px;
}

.bm-burger-bars {
  background: #FFF;
}

.bm-burger-bars-hover {
  background: #a90000;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #000;
  padding: 0em 0em 0;
  font-size: 2em;
  /* A lenyíló menü fentről indul */
  top: 0;
  bottom: unset;
}

  /* Double */

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  /* A menü elől látszódjon, ha kinyílik */
  z-index: 102;
}

  /* React Burger Menu stílusok */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 13vh
}



.bm-overlay {
  position: fixed;
  height: 100%;
  top: 13vh;
  background: rgba(0, 0, 0, 0.3);
}

/* Testre szabás: hozzáadhatod az egyedi stílusokat és elhelyezkedéseket */
.menu-item {
  display: block;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  font-family: 'Russo', sans-serif;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  color: #555;
}

/* Logo stílusai */
.logo-container {
  display: flex;
  padding: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.menu-logo {
  width: 100px; /* Állítsd be a megfelelő méretet */
  height: 100px;
}

@media screen and (max-width: 767px) {


  .menu-container{
    height: 12vh !important;
    position: sticky;
    width: 100vw !important;
    z-index: 100;
    box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.4) !important;
  }
}