:root {
  --background-light: #2e2e2e;
  --background-darker: #1c1c1c;
  --red-light: #960000;
  --red-darker: #800000;
  --image-shadow: drop-shadow(5px 5px 5px #000000);
  --button-light: #d2d2d2;
  --button-dark: #494949;
}


.full-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 90vh;
    background-color: #000;
  }
  
  .title {
    font-weight: bold;
    font-size: 50px;
    font-family: 'Russo', sans-serif !important;
    color: white;
    margin-top: 20px;
  }

  .hasab {
    display: flex;
    justify-content: space-between;
    width: 59%; /* Módosítjuk a hasább szélességét */
    background-color: #000;
    padding: 10px;
    border-radius: 20px;
    border: 2px solid white; /* Fehér keret a hasábban */
  }
  
  .felirat-left,
  .felirat-middle,
  .felirat-right {
    font-size: 18px;
    font-weight: bold;
    flex: 1; /* Egyenlő részt fog elfoglalni a hasábban */
    text-align: center; /* Középre igazítjuk a szöveget */
    color: white;
    font-family: 'Russo', sans-serif !important;
    letter-spacing: 2px;
  }
  
  .felirat-middle {
    border-left: 2px solid white; /* Fehér keret a középső felirat bal oldalán */
    border-right: 2px solid white; /* Fehér keret a középső felirat jobb oldalán */
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
  
  .image-container img {
    width: 250px; /* Nagyobb méretű képek */
    height: 200px;
    margin: 15px;
    border-radius: 10px; /* Kerekített sarkok */
    border: 2px solid white; /* Fehér körvonal */
  }


  @media only screen and (max-width: 767px) {


    .full-screen-container{
      height: 85vh;
    }

    .title{
      font-size: 30px;
    }

    .hasab{
      width: 80%;
    }

    .image-container img {
      width: 90px; /* Kisebb méretű képek telefonon */
      height: 100px;
      margin: 10px;
    }
  }
  