:root {
  --background-light: #2e2e2e;
  --background-darker: #1c1c1c;
  --red-light: #960000;
  --red-darker: #800000;
  --image-shadow: drop-shadow(5px 5px 5px #000000);
  --button-light: #d2d2d2;
  --button-dark: #494949;
}

.shark {
  width: 80%;
  -webkit-filter: var(--image-shadow);
  filter: var(--image-shadow);
  padding: 4vh;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 100px;
}

.contact-title{
  font-family: 'Russo', sans-serif !important;
  color: #960000;
  background-color: white;
  position: relative;
  border-radius: 30px;
  border: 2px black solid;
  text-align: center;
  justify-content: center; /* Vízszintesen középre */
  align-items: center; /* Függőlegesen középre */
  display: flex;
  margin: 0;
  height: 8vh;
  width: 80%;
  margin-left: 11%;
  margin-bottom: 2%;
  font-size: large;
}


.contact_container {
  display: flex;
  height: 110vh;
  background: var(--background-light);
  border-left: 5px black solid;
  border-right: 5px black solid;

}

.contact_image-container{
  background: none;
}

.contact_left-column {
  width: 50%;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_right-column {
  margin-top: 8vh;

  width: 60%;
  background-color: none;
  padding: 20px;
  display: flex;
  flex-direction: column; /* Új rész hozzáadva */
  border-radius: 100px 0px 0px 100px;
}
/*
.contact_image-container img {
  width: 1000px;
  height: 640px;
}
*/
.contact-header {
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-align: center; /* Középre igazítás */
  font-family: 'Russo', sans-serif !important;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-row {
  margin-top: 2vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 80%;
}

.form-group {
  width: 45%;
  
}

label {
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
  font-family: 'Russo', sans-serif !important;
  letter-spacing: 2px;
}

input[type='text'],
input[type='email'],
input[type='tel'],
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

textarea {
  resize: none;
}

.button-container {
  display: flex;
  justify-content: center;
}

.button-container button {
  background-color: #960000;
  color: #fff;
  border: 2px solid rgb(0, 0, 0);
  font-size: large !important;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  font-family: 'Russo', sans-serif !important;
  height: 10vh;
  width: 30vh;
}

.button-container button:hover {
  background-color: #FFF;
  color: #000;
}

.form-group-100 {
  width: 100%;
}



.bottom-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #fff;
  height: 5vh;
}

.bottom-content p {
  font-size: 18px;
}

.footer {
  background-color: black;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;

}

.footer-top {
  display: flex;
  justify-content: space-evenly; /* Egyenletes elosztás */
  align-items: center;
  width: 100%;
  padding-left: 10% !important;
  padding: 20px 0; /* Ad egy kis térközt a láblécben */
}


.footer-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 15%; /* Megakadályozza az összenyomódást */
}

.footer-item.large {
  flex: 2; /* A nagy elemek kétszer akkora helyet kapnak */
}


.footer-bottom {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  width: 100%;
}


.footer-item img {
  width: 70px; /* Állítsd be az ikonok méretét */
  height: auto;
  margin-bottom: 10px; /* Adjon helyet a szöveg és az ikon között */
}

.footer-item img:hover {
  transform: scale(1.2);
}

.footer-item p {
  font-size: small;
  font-family: 'Russo', sans-serif;
  letter-spacing: 1px;
  margin-top: 5px;
}



  .feedback_success{
  background-color: green;
  margin-top: 10px;
  padding: 5px 10px 5px 10px; 
  border-radius: 30px;
  color: #fff;
  }

  .feedback_error{
  background-color: red;
  margin-top: 5px;
  padding: 5px 10px 5px 10px; 
  border-radius: 30px;
  color: #fff;
  }

  .feedback_success,
  .feedback_error p{
    font-family: 'Russo', sans-serif !important;
    letter-spacing: 2px;
  }

  .footer-telefon{
    font-size: 1.25vw;
    align-self: center;
  }

@media only screen and (max-width: 767px) {

  .contact_footer_container{
    width: 100%;
  }
  .contact_container{
    width: 100%;
    background: white;
    height: 80vh;
    margin-top: 0vh;
  }



  .contact-title-mobile{
         font-family: 'Russo', sans-serif !important;
    color: #960000;
    background-color: white;
    border-radius: 15px;
    border: 2px black solid ;
    text-align: center;
    margin: 0;
    margin-top:-8vh;
        grid-row: 1; /* Adjusted to 1 (grid rows start at 1) */
      font-size: 3vh !important;
      height: 6vh;
      width: 80vw;
          /* Center vertically and horizontally */
    display: flex;
    justify-content: center; /* Horizontal alignment */
    align-items: center; /* Vertical alignment */
    font-family: 'Russo', sans-serif !important;
    margin-left: 5%;
  }

  .contact_right-column{
    width: 100%;
    padding: 5px;
    background-color: #FFF;
  }

  .contact-header{
    color: #000;
  }

  label {
    color: #000;
  }

input[type='text'],
input[type='email'],
input[type='tel'],
textarea{
  border: 2px solid #000;
}

.button-container-mobile button{
  background-color:#960000;
  color: white;
  border: 2px solid #000;
  margin-top: 0px;
  padding: 10px 20px;
  width: 100%;
}

.button-container-mobile button:hover {
  background-color: #000;
  color: #FFF;
}


  .footer{
    
    width: 100%;
    height: 35vh;
    padding: 5%;
  }

  /* Felső sor (2 oszlop, elválasztva) */
  .footer-top {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding-top: 2%;
  }

  .footer-item.large {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .footer-item.large p {
    font-size: x-small !important;
  }

  
  .footer-item img {
    width: 50%; /* Állítsd be az ikonok méretét */
    height: auto;
    margin-bottom: 10px; /* Adjon helyet a szöveg és az ikon között */
  }

  .footer-item p{
    font-size: x-small !important;
  }
  
  .footer-item.large img {
    width: 30%; /* Állítsd be az ikonok méretét */
    height: auto;
    margin-bottom: 10px; /* Adjon helyet a szöveg és az ikon között */
  }

}