/* BEGIN ================================ Main container  ================================== */

.shop-main-container-sws {
  background-color: #2e2e2e;
  height: 760vh;
  width: 100vw;
  margin-top: 0%;
  display: grid;
  grid-template-rows: 2% 98%;
  grid-template-columns: 5% 20% 50% 20% 5%;
  min-height: auto; 

}

.fake-header-sws {
  grid-row: 1;
  grid-column: 3;
  font-size: xx-large !important;
  width: 100%; /* Use full width */
  height: 80%; /* Use full height */
  color: #960000;
  background-color: white;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, .4);
  font-family: 'Russo', sans-serif;
  display: flex; /* Enable flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  margin-top: 3vh;
}

.button-back-sws {
  font-family: 'Russo', sans-serif;
  padding: 10px;
  grid-row: 1;
  grid-column: 2;
  margin: 10%;
  padding: 4%;
  font-size: 2vw;
  margin-top: 10%;
  height: 60%;
  width: 60%;
  color: #960000;
  background-color: white;
  border-radius: 20px;
  border-width: 0px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, .4);
  text-decoration: none;
  text-align: center;
  justify-self: center;
}

.button-back-sws:hover{
  background-color: #cfcece;
  color: #c80000;
  border: 2px solid #000000;

}
/* END ================================ Main container  ================================== */

/* START ================================ Shop container  ================================== */

/* START ================================ IMG & Basic info ================================== */
.shop-item-container-sws {
  grid-row: 2;
  grid-column: 2/5;
  text-align: justify; /* Center the content */
  margin-top:5%;
  margin-bottom: 5%;
  display: grid;
  grid-template-columns: 45% 55%; 
  /* =                Price, Gallery, Benefits, Sizes, Komp, Video, TryOut, Links */
  grid-template-rows:  auto     auto      auto     auto    auto  auto    auto   auto  auto;
   background-color: white;
  border-width: 2px;
  border-color: black;
  border-radius: 40px;
  border-style: solid;
    padding: 5%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Add a box shadow */
    font-family: 'Russo', sans-serif;
    min-height: 0;  /* NEW */
    min-width: 0;
        justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 95%;
  }
  
  
  .img-container-sws {
    margin: 2%;
    grid-column: 1;
    grid-row: 1;
    min-height: 0;  /* NEW */
    min-width: 0;
    padding: 2%;
  } 
  
  .text-container-sws {
    margin: 3%;
    grid-column: 2;
    grid-row: 1;
    height: 100%;
    min-height: 0;  /* NEW */
    min-width: 0;
  }
  
.shop-item-container-sws img {
    display: block; /* Ensures margin auto works */
    margin: 0 auto; /* Centers the image horizontally */
    max-width: 80%;
    height: auto;
    filter: drop-shadow(5px 5px 5px #000);
}
  
  .shop-item-container-sws h2 {
    font-size: xx-large;
    margin-top: 20px;
    text-align: center;
  }
  
  .shop-item-container-sws h3 {
    font-size: x-large;
    margin-top: 20px;
    text-align: left;
  }
  .shop-item-container-sws p {
    font-size: large;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .shop-item-container-sws ul {
    list-style: none;
  font-size: medium;

    padding: 0;
    list-style-type: circle;
}

.shop-item-container-sws li {
  font-size:large;
  margin-bottom: 5px;
  margin-left: 40px;
}

.price_a-sws{
  font-size: 4vh;
  font-style: italic;
background-color: #960000;
border-radius: 10px;
padding: 4%;
margin: 2%;
width: 90%;
margin-right: 4%;
box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.847); /* Box shadow for button */

}

.scrollToOffer-sws-button{
  position: relative;
  font-family: 'Russo', sans-serif;
  right: 0%;
  top: 10%;
  width: 40%;
  height: 10%;
  padding: 1vh 2vh; /* Button padding */
  font-size: 1.5vw; /* Button font size */
  background-color: #960000; /* Button background color */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.691); /* Box shadow for button */
  color: white; /* Button text color */
  border-radius: 20px; /* Remove border */
  cursor: pointer; /* Pointer cursor on hover */
}

.scrollToOffer-sws:hover {
  background-color: #4e4e4e; /* Button hover color */
  color: #800000;
}

/* END ================================ IMG & Basic info ================================== */

/* START ================================ Image gallery  ================================== */

.gallery-container-sws {
  grid-column: 1/3;
  background-color: #ffffff;
  flex: 0 0 40%;
  display: grid;
  grid-template-columns: 5% 90% 5%;
  grid-gap: 4vh;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
}

.gallery-title-sws {
  grid-column: 1 / -1; /* Span across the entire width */
  text-align: center; /* Center the h2 */
  margin-bottom: 0; /* Adjust space between title and gallery */
  font-size: 2rem; /* Adjust font size */
  color: #000000; /* Title color */
}

.gallery-grid-sws {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2vh; /* Reduced space between images */
  width: 100%;
  grid-column: 2;
}

.gallery-image-sws {
  width: 100%;
  border-radius: 1vh;
  object-fit: contain;
  cursor: pointer;
}

.prev-btn-sws, .next-btn-sws {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  box-shadow: none;
}

.prev-btn-sws {
  left: calc(50% - 70vh);
  transform: scaleX(-1);
  margin-top: -3vh;
  
}

.next-btn-sws {
  right: calc(50% - 70vh);
}

.prev-btn-sws img, .next-btn-sws img {
  width: 100%;
  height: 100%;
  box-shadow: none Im !important;
  filter: none;
  border-radius: 20px;
}

.image-description-sws {
  display: flex;
  justify-content: center;
  align-content: baseline;
}

/* END ================================ Image gallery  ================================== */

/* START ================================ Vorteil/NachTeil  ================================== */
.benefits-sws {
  grid-row: 3;
  grid-column: 1 / -1; /* Span across all columns */
  gap: 2%; /* Adjust the gap between the columns */
  
  height: 90%;

}
.benefits-sws h2{
  font-size: xx-large;
}
/* Common styles for both lists */
.benefit-list
{
  padding: 2vh;   
  background-color: #cccccc;
  border-radius: 1vh;
  border: 2px solid #929292;
  border-radius: 10px;
  list-style-type: none;
  height: inherit;
}
.ul-benefit{
   list-style-type: none !important;
}

.ul-benefit li {
font-size: large;
}

.size-range-container {
  grid-column: 1 / -1;
  margin-top: -0%;
  display: grid;
  grid-template-columns: 1fr 1fr ; /* Two columns */
  grid-template-rows:10% 55% 30% ;
  grid-gap:  20px; /* Space between columns */
  background-color: #cccccc;
  border: 2px solid #929292;
  border-radius: 10px;
  padding: 20px; /* Added padding for better spacing */
  height: 90%;
}

.size-range-container h2 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center; /* Center the heading */
  margin-bottom: 20px; /* Spacing below the heading */
  color: #000000; /* Adjust as needed */
  grid-row: 1;
  height: 10%;
  font-size: xx-large;
}
.size-range-list-50 {
  padding: 2vh; /* Padding inside the section */
  margin-right: 3%;
  margin-left: 2%;
  grid-column: 1; /* Set in the first column */
  grid-row: 2; /* Align in the first row */
  margin-bottom: 4%;

}

.smartbox-info-list{
  grid-column: 1;
  grid-row: 3;
}

.cable-info-list{
  grid-column: 2;
  grid-row: 3;

}

.size-range-list-100 {
  padding: 2vh; /* Padding inside the section */
  margin-right: 3%;
  margin-left: 2%;
  margin-bottom: 4%;
  grid-column: 2; /* Set in the second column */
  grid-row: 2; /* Align in the same row as the first list */
}



.size-range-list-50, .size-range-list-100, .cable-info-list, .smartbox-info-list {
  padding: 2vh;
  background-color: #f5f5f5;
  border-radius: 1vh;
  border: 1px solid #ddd;
}



.mukodj{
  list-style-type: disc;
  margin-left: 2vh;
  font-size: 2vw;
}

.mukodj li{
  font-size: 1.25vw;
  padding: 1%;
}


/* END ================================ Vorteil/NachTeil  ================================== */

/* START ================================ DIN part  ================================== */


.inset-img {
  grid-row: 5;
  grid-column: 1;
  width: 70%;
  margin: 10%;
}

.inset-list {
  grid-row: 5;
  grid-column: 2;
  padding: 4%;
}



.inset-list ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
}

.inset-list ul li {
  margin: 10px 0; /* Spacing between list items */
  color: black; /* Ensure text is visible */
  font-size: 1.5rem; /* Adjust font size */
  cursor: pointer;
}

/* END ================================ DIN  part ================================== */

/* START ================================ Device Compatibilty  part ================================== */


.device-img-comp {
  grid-row: 5;
  grid-column: 2;
  width: 60%;
  margin: 15% !important;
  margin-top: 20% !important;
  justify-self: center;
  align-self: center;
}

.comp-list {
  
  grid-row: 5;
  grid-column: 1;
  padding: 4%;
  margin-top: -10%;
  height: 90%;
}

.comp-list ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
}

.comp-list li {
  margin: 10px 0; /* Spacing between list items */ 
  color: black !important; /* Ensure text is visible */
  font-size: 1.5rem; /* Adjust font size */
  cursor: pointer;
}

/* Hide the native radio button */
.comp-list input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #800000; /* Change to your desired border color */
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease;
}

/* Style for when the radio button is checked */
.comp-list input[type="radio"]:checked {
  background-color: #800000;/* Change to your desired checked background color */
}

.comp-list input[type="radio"]:checked::after {
  content: '';
  width: 8px;
  height: 8px;
  background: white; /* Inner circle color */
  border-radius: 50%;
  position: absolute;
  top: 4px; /* Centering the inner circle */
  left: 4px;
}

.comp-list label {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: black;
  cursor: pointer;
}

.comp-list input[type="radio"] {
  margin-right: 10px;
}

.two-column {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal-width columns */
  gap: 15px; /* Space between columns and rows */
}

.radio-label {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  color: black;
}


/* END ================================ Device Compatibilty  part ================================== */
/* START ================================ YOtube video  part ================================== */
.yt-video-container {
  padding-top: 2%;
  grid-row: 6;
grid-column: 1/3;
align-items: top;
justify-content: center;
border-top: 2px solid black;
height: 150%;
}


.yt-frame {
  justify-content: center;
  width: 70%;
  margin-left: 15%;
  margin-right: 15%;
  height: 60%;
  border: 0.5vh solid #a70000;
  border-radius: 20px;
  box-shadow: 0 10px 14px rgb(0, 0, 0); /* Add a box shadow */
}

/* END ================================ YOtube video  part ================================== */

/* Start ================================ Offer  part ================================== */

.form-section-sws {
  grid-row: 7;
  grid-column: 1/3;
  display: flex;
  height: 100%;
  background-color: #ffffff;
  color: white;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  margin: 0;
  border-bottom: 2px solid black;
border-top: 2px solid black;

}

.form-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1vh; /* Csökkentett padding */
}

.form-container-sws-h2 {
  font-family: 'Russo', sans-serif;
  font-size: 3vh !important;
  color: #000000;
  margin-bottom: 0vh;
}

.form-container-sws-p {
  font-family: 'Russo', sans-serif;
  font-size: 2vh;
  color: #000000;
  margin: 2%;
  justify-content: center;
  text-align: center;
}

form {
  margin-top: 0vh;
  padding: 10px;
}

form input {
  width: calc(50% - 0.5vh);
  padding: 0.5vh; /* Csökkentett padding */
  border: 0.1vh solid #800000;
  border-radius: 0.5vh;
  background-color: #ffffff;
  color: #000000;
  margin-left: 2%;
}

form button {
  color: #000;
  width: 50%;
  margin-top: 1.5vh;
  padding: 1vh 0;
  background-color: white;
  font-family: 'Russo', sans-serif !important;
  font-size: 2.5vh;
  cursor: pointer;
  text-align: center;
  color: #ffffff;
  background-color: #960000; /* Button background color */
  border-color: #000;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, .4);
}

form button:hover {
  background-color: #000;
  border: 0.1vh solid #fff;
  color: #fff;
}

input[type="text"], input[type="email"], input[type="tel"] {
  font-family: 'Russo', sans-serif !important;
  color: #800000;
  text-align: center;
  width: 100%;
  padding: 1%;
  margin: 1% 1% 0% 1%;
  border-radius: 30px; /* Similar to button style */
  border: 2px black solid;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); /* Adding box shadow */
  font-size: 16px;
  background-color: #f9f9f9; /* Matching background */
}

input[type="text"]::placeholder, 
input[type="email"]::placeholder, 
input[type="tel"]::placeholder {
  color: #80000091;
  text-align: center;
    opacity: 1; /* Ensures that the color is not faded (default can be semi-transparent) */
}


input[type="text"]:focus, input[type="email"]:focus, input[type="tel"]:focus {
  outline: none;  
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); /* Adding box shadow */

  border: 2px solid #000000;
}


button[type="submit"]:hover{
  background-color: #4e4e4e;
  color: #c80000;
  border: 2px solid #000000;

}
/* END ================================ Offer  part ================================== */
/* Start ================================ App links  part ================================== */
.app-link-sws {
  grid-row: 8; /* Position in the grid */
  grid-column: 1 / -1; /* Span across all columns */
  display: grid;
  grid-template-rows: 50% 50%; /* Two rows, each taking half the space */
  grid-template-columns: 50% 50%; /* Two columns, each taking half the space */
  gap: 2vh; /* Space between grid items */
}

/* Row 1, Col 1: Title */
.app-link-sws h2 {
  align-self: end; /* Stick the title to the bottom */
  grid-row: 1; /* Position in the grid */
  grid-column: 1; /* First column */
  font-size: 2rem; /* Title font size */
  text-align: center; /* Center the title */
  color: #000; /* Change color if needed */
}

/* Row 2, Col 1: Email form and download button */
.email-download-form {
  grid-row: 2; /* Position in the grid */
  grid-column: 1; /* First column */
  display: flex; /* Flexbox for horizontal alignment */
  justify-content: center; /* Align children to the right */
  align-items: center; /* Center items vertically */
  height: 20%; /* Height of the form */
  margin-left: 5%; /* Margin from the left */
}

.email-download-form input {
  padding: 1vh; /* Input padding */
  font-size: 1rem; /* Input font size */
  border-radius: 0; /* No rounding */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.691); /* Box shadow for input */
  border: none; /* Remove border */
}

.email-download-form button {
  padding: 1vh 2vh; /* Button padding */
  font-size: 1rem; /* Button font size */
  background-color: #960000; /* Button background color */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.691); /* Box shadow for button */
  color: white; /* Button text color */
  border: none; /* Remove border */
  cursor: pointer; /* Pointer cursor on hover */
}

.email-download-form button:hover {
  background-color: #4e4e4e; /* Button hover color */
}

/* Row 1, Col 2: Android download */
.android-link {
  grid-row: 1; /* Position in the grid */
  grid-column: 2; /* Second column */
  display: flex; /* Flexbox for vertical alignment */
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-end; /* Align to the bottom */
  align-items: center; /* Center items horizontally */
  height: 100%; /* Use full height */
  text-decoration: none; /* Remove underline from link */
}

.android-link img {
  width: 50%; /* Image size */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 1vh; /* Space below the image */
  border: none; /* Remove border */
  background: none; /* Ensure no background is applied */
  -webkit-filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
  filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
  box-shadow: none;

}

.android-link p {
  font-size: 1rem; /* Text size */
  color: #000; /* Text color */
  text-align: center; /* Center the text */
}

/* Row 2, Col 2: iOS download */
.ios-link {
  grid-row: 2; /* Position in the grid */
  grid-column: 2; /* Second column */
  display: flex; /* Flexbox for vertical alignment */
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start; /* Align to the top */
  align-items: center; /* Center items horizontally */
  height: 100%; /* Use full height */
  text-decoration: none; /* Remove underline from link */
}

.ios-link img {
  width: 50%; /* Image size */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 1vh; /* Space below the image */
  border: none; /* Remove border */
  background: none; /* Ensure no background is applied */
  -webkit-filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
  filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
  box-shadow: none;
}

.ios-link p {
  font-size: 1rem; /* Text size */
  color: #000; /* Text color */
  text-align: center; /* Center the text */
}


.message-sending-animation {
  width: 50px;
  height: 50px;
  position: relative;
  margin: 20px auto; /* Center the animation */
  animation: sendMail 2s infinite ease-in-out;
}

@keyframes sendMail {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px); /* Moves the envelope up */
  }
  100% {
    transform: translateY(0);
  }
}

/* Envelope graphic */
.message-sending-animation:before {
  content: '✉️'; /* Envelope emoji */
  font-size: 50px;
  display: block;
  text-align: center;
}

/* END ================================ App links  part ================================== */

.logo{
  grid-row: 9;
  grid-column: 1/-1;
  -webkit-filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
  filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
  align-self: center;
  height: 40%;
  width:40%;
  display: block;
  margin: auto;
  border: none; /* Remove border */
  background: none; /* Ensure no background is applied */
  -webkit-filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
  filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
  box-shadow: none !important;
}


/* END ================================ Shop container  ================================== */


.size-range-container-din50 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left; /* Ensures text stays centered */
  grid-column: 1 / -1; /* Ensures it spans full width */
  background-color: #cccccc;
  border: 2px solid #929292;
  border-radius: 10px;
  padding: 20px;
  height: auto; /* Allow it to adjust dynamically */
  width: 95%;
  margin: 0 auto; /* Centers horizontally */
}


.size-range-container-din50 h2 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center; /* Center the heading */
  margin-bottom: 20px; /* Spacing below the heading */
  color: #000000; /* Adjust as needed */
  grid-row: 1;
  height: 10%;
  font-size: xx-large;
}

.size-range-list-50-din50 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.size-img{
  max-width: 100% !important;
}


.size-range-container-smartbox-only{
   display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left; /* Ensures text stays centered */
  grid-column: 1 / -1; /* Ensures it spans full width */
  background-color: #cccccc;
  border: 2px solid #929292;
  border-radius: 10px;
  padding: 20px;
  height: auto; /* Allow it to adjust dynamically */
  width: 95%;
  margin: 0 auto; /* Centers horizontally */
  gap: 2vh;
}

.size-range-container-smartbox-only h2 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center; /* Center the heading */
  margin-bottom: 20px; /* Spacing below the heading */
  color: #000000; /* Adjust as needed */
  grid-row: 1;
  height: 10%;
  font-size: xx-large;
}