@media screen and (max-width: 768px) {

/* BEGIN ================================ Main container  ================================== */


.shop-main-container-sws {
    background-color: #2e2e2e;
    height: 700vh;
    width: 100vw;
    margin-top: 0%;
    display: grid;
    grid-template-rows: 2% 102%;
    grid-template-columns: 5% 20% 50% 20% 5%;
    margin-top: 20%;
  }
  
  .fake-header-sws {
    margin-top: 20%;
    grid-row: 1;
    grid-column: 3;
    font-size: 3vh;
    width: 100%; /* Use full width */
    height: 50%; /* Use full height */
    color: #960000;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .4);
    font-family: 'Russo', sans-serif;
    display: flex; /* Enable flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
  }
  
  .button-back-sws {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%; /* Fix méret */
    height: 50%;
    background-color: white;
    border-radius: 15px;
    grid-column: 3;
    margin-left: -150% !important;
    margin-top: 20% !important;
    border: none;
    cursor: pointer;
  }
  
  .button-back-sws:hover{
    background-color: #cfcece;
    color: #c80000;
    border: 2px solid #000000;
  
  }
  /* END ================================ Main container  ================================== */
  
  /* START ================================ Shop container  ================================== */
  
  /* START ================================ IMG & Basic info ================================== */
  .shop-item-container-sws {
    grid-row: 2;
    grid-column: 2/5;
    text-align: justify; /* Center the content */
    margin-top:5%;
    margin-bottom: 10%;
    display: grid;
    grid-template-columns: 45% 55%; 
    /* =               Price, Gallery, Benefits, Sizes,  Komp, Video, TryOut, Links */
    grid-template-rows: auto     auto       auto      auto      auto    auto    auto   auto   auto;
     background-color: white;
    border-width: 2px;
    border-color: black;
    border-radius: 40px;
    border-style: solid;
      padding: 5%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Add a box shadow */
      font-family: 'Russo', sans-serif;
      min-height: 0;  /* NEW */
      min-width: 0;
    }
    
    .main-info{
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 15% 30% 50% auto;
        grid-column: 1/-1;
        margin-bottom: 10%;
    }
    
    .title-mobile{
        grid-row: 1;
        grid-column: 1/-1;
    }

    .img-container-sws {
      grid-column: 1/-1;
      grid-row: 2 !important;
      grid-row: 1;
      margin-top: -15% !important;
      margin: 2%;
      min-height: 0;  /* NEW */
      min-width: 0;
      padding: 2%;
      border: none !important;
      box-shadow: none !important;
      -webkit-filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
      filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
    } 

    .img-container-sws img{
      all:unset;
    }
    
    .text-container-sws {
      justify-content: center;
      text-align: center;
        grid-column: 1/-1;
        grid-row: 3;
      margin: 3%;
      height: 100%;
      min-height: 0;  /* NEW */
      min-width: 0;
    }
    
    .shop-item-container-sws img {
      max-width: 100%; /* Ensure the image doesn't exceed its container's width */
      height: auto; /* Maintain aspect ratio */
      border: none !important;  
       box-shadow: none !important;

    }
    
    .shop-item-container-sws h2 {
      font-size: x-large;
      margin-top: 20px;
      text-align: center;
    }
    
    .shop-item-container-sws h3 {
      font-size: x-large;
      margin-top: 20px;
      text-align: left;
      
    }
    .shop-item-container-sws p {
      font-size: medium;
      line-height: 1.5;
      margin-bottom: 10px;
      text-align: justify;
    }
    
    .shop-item-container-sws ul {
      list-style: none;
    font-size: small;
  
      padding: 0;
      list-style-type: circle;
  }
  
  .shop-item-container-sws li {
    font-size: small;
    margin-bottom: 5px;
    margin-left: 5%;
  }
  
  .price_a-sws{
    font-size: 4vw;
    font-style: italic;
    color: white;
  background-color: #960000;
  border-radius: 10px;
  grid-column: 1/-1;
  grid-row: 4;
  padding: 4%;
  margin: 3%;
  width: 90%;
  text-align: center;
  margin-right: 4%;
  margin-bottom: 4%;
  box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.847); /* Box shadow for button */
  
  }
  
  .scrollToOffer-sws{
    grid-column: 2 !important;
    grid-row: 4 !important;
    position: inherit;
    font-family: 'Russo', sans-serif;
    width: 90%;
    margin-left: 4%;
    height: 90%;
    font-size: 3vw; /* Button font size */
    background-color: #960000; /* Button background color */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.691); /* Box shadow for button */
    color: white; /* Button text color */
    border-radius: 20px; /* Remove border */
    cursor: pointer; /* Pointer cursor on hover */
    margin-bottom: 10%;

  }
  
  .scrollToOffer-sws:hover {
    background-color: #4e4e4e; /* Button hover color */
    color: #800000;
  }
  
  /* END ================================ IMG & Basic info ================================== */
  
  /* START ================================ Image gallery  ================================== */
  
  .gallery-container-sws {
    grid-column: 1/3;
    background-color: #ffffff;
    flex: 0 0 40% !important;
    display: grid;
    grid-template-columns: 10% 80% 10%;
    grid-gap: 1vh;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
  }
  
  .gallery-title-sws {
    grid-column: 1 / -1; /* Span across the entire width */
    text-align: center; /* Center the h2 */
    margin-bottom: 0; /* Adjust space between title and gallery */
    font-size: 2rem; /* Adjust font size */
    color: #000000; /* Title color */
  }
  
  .gallery-grid-sws {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1vh; /* Reduced space between images */
    width: 100% !important;
    grid-column: 2;
    padding-left: 10%;
    }
  
  .gallery-image-sws {
    width: 80%;
    -webkit-filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
    filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
    border-radius: 1vh;
    object-fit: fill;
    border: 0.1vh solid #000000; /* Reduced border thickness */
    cursor: pointer;
  }
  
  .prev-btn-sws{
    grid-column: 1;
    transform: scaleX(-1);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    box-shadow: none;
  }
  .next-btn-sws {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    box-shadow: none;
    grid-column: 3;
    right: 0%;
  }
  
  .prev-btn-sws img, .next-btn-sws img {
    width: 100%;
    height: 100%;
  }
  
  .image-description-sws {
    display: flex;
    justify-content: center;
    align-content: baseline;
   
    font-size: small !important;
  }
  
  /* END ================================ Image gallery  ================================== */
  
  /* START ================================ Vorteil/NachTeil  ================================== */
  .benefits-sws {
    grid-row: 3;
    grid-column: 1 / -1; /* Span across all columns */
    gap: 2%; /* Adjust the gap between the columns */
    padding: 1vh; /* Padding inside the section */
    margin-right: 2%;
    margin-left: 2%;
    margin-bottom: 0;
    height: 100%;
  }
  
  .ul-benefit{
    list-style-type: none !important;
  margin: 0;
  padding: 10% !important;
}
/* Common styles for both lists */
.benefit-list
{
  padding: 1vh;   
  background-color: #cccccc;
  border-radius: 1vh;
  border: 2px solid #929292;
  border-radius: 10px;
  
}
/* END ================================ Vorteil/NachTeil  ================================== */
  
/* START ================================ SIZE CONTAINERS ==================================*/

.size-range-container {
  grid-column: 1 / -1;
  margin-top: 0;
  margin-right: 3%;
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  grid-gap: 1%; /* Space between columns */
  background-color: #cccccc;
  border: 2px solid #929292;
  border-radius: 10px;
  padding-left: 20px !important; /* Added padding for better spacing */
  height: 100%;
}

.size-range-container h2 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center;  /* Spacing below the heading */
  color: #333; /* Adjust as needed */
  grid-row: 1;
  height: 2%;
  font-size:6vw;
}
.size-range-container h3 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center;  /* Spacing below the heading */
  color: #333; /* Adjust as needed */
  grid-row: 1;
  height: 10%;
  margin: 4%;
  font-size:4vw;
}
.size-range-container h4 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center;  /* Spacing below the heading */
  color: #333; /* Adjust as needed */
  grid-row: 1;
  height: 10%;
  margin: 4%;
  font-size:3vw;
}
.size-range-list-50 {
  padding: 2vh; /* Padding inside the section */
  margin-right: 3%;
  margin-left: 2%;
  grid-column: 1; /* Set in the first column */
  grid-row: 2; /* Align in the first row */
  margin-bottom: 4%;
  
  
}

.size-range-list-100 {
  padding: 2vh; /* Padding inside the section */
  margin-right: 3%;
  margin-left: 1%;
  margin-bottom: 4%;
  grid-column: 1; /* Set in the second column */
  grid-row: 2; /* Align in the same row as the first list */
}


.size-range-list-50, .size-range-list-100 {
  padding: 2vh;
  background-color: #f5f5f5;
  border-radius: 1vh;
  border: 1px solid #ddd;
}

.mukodj{
  list-style-type: disc;
  margin-left: 0vh;
  font-size: 6vw;
}

.mukodj li{
  font-size: 3.25vw;
  padding: 1%;
  text-align: justify  !important;
}

/* END ================================ SIZE CONTAINERS ==================================*/


/* START ================================ DIN part  ================================== */

.inset-img {
    grid-row: 5;
    grid-column: 1;
    width: 70%;
    margin: 10%;
  }
  
  .inset-list {
    grid-row: 5;
    grid-column: 2;
    padding: 4%;
  }
  
  .inset-list ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
  }
  
  .inset-list ul li {
    margin: 10px 0; /* Spacing between list items */
    color: black; /* Ensure text is visible */
    font-size: 1.5rem; /* Adjust font size */
    cursor: pointer;
    text-align: justify;
  }
  
  /* END ================================ DIN  part ================================== */
  
  /* START ================================ Device Compatibilty  part ================================== */
  
  
  .device-img-comp {
    grid-row: 5;
    grid-column: 1/-1;
    width: 50%;
    margin-left: 25% !important;
    justify-self: center;
    align-self: center;
  }
  
  .comp-list {
    margin-top: 30%;
    grid-row: 5;
    grid-column: 1/-1;
    padding: 4%;
    height: 110%;
  }
  
  .comp-list ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
  }
  
  .comp-list li {
    font-size: 4vw !important;
    margin: 10px 0; /* Spacing between list items */ 
    color: black !important; /* Ensure text is visible */
    font-size: 1.5rem; /* Adjust font size */
    cursor: pointer;
  }
  
  .comp-list input[type="checkbox"] {
    margin-right: 10px; /* Space between checkbox and label */
  }
  
  
  
  /* END ================================ Device Compatibilty  part ================================== */
  /* START ================================ YOtube video  part ================================== */
  .yt-video-container {
    padding-top: 1%;
    grid-row: 6;
    grid-column: 1/3;
    align-items: top;
    justify-content: center;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    
  }
  
  
  .yt-frame {
    justify-content: center;
    width: 96%;
    margin-top: 10%;
    margin-left: 2%;
    margin-right: 2%;
    height: 38%;
    border: 0.5vh solid #a70000;
    border-radius: 20px;
    box-shadow: 0 10px 14px rgb(0, 0, 0); /* Add a box shadow */
  }
  
  /* END ================================ YOtube video  part ================================== */
  
  /* Start ================================ Offer  part ================================== */
  
  .form-section-sws {
    grid-row: 7;
    grid-column: 1/3;
    display: flex;
    height: 100%;
    background-color: #ffffff;
    color: white;
    align-items: stretch;
    justify-content: center;
    padding: 0;
    margin: 0;
    border-bottom: 2px solid black;
  
  }
  
  .form-container-sws {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1vh; /* Csökkentett padding */
  }
  
  .form-container-sws h2 {
    font-family: 'Russo', sans-serif;
    font-size: 3vh;
    color: #000000;
    margin-bottom: 0vh;
  }
  
  .form-container-sws p {
    font-family: 'Russo', sans-serif;
    font-size: 2vh;
    color: #000000;
    margin: 2%;
    justify-content: center;
    text-align: center;
  }
  
  .form-sws {
    margin-top: 0vh;
    padding: 10px;
  }
  
  .form-sws input {
    width: calc(60% - 0.5vh);
    padding: 0.5vh; /* Csökkentett padding */
    border: 0.1vh solid #800000;
    border-radius: 0.5vh;
    background-color: #ffffff;
    color: #000000;
    margin-left: 2%;
  }
  
  .form-row-sws {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    gap: 3vw;
}


  .form-sws button {
    color: #000;
    width: 70%;
    margin-top: 1.5vh;
    padding: 1vh 0;
    background-color: white;
    font-family: 'Russo', sans-serif !important;
    font-size: 2.5vh;
    cursor: pointer;
    text-align: center;
    color: #ffffff;
    background-color: #960000; /* Button background color */
    border-color: #000;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .4);
  }
  
  .form-sws button:hover {
    background-color: #000;
    border: 0.1vh solid #fff;
    color: #fff;
  }
  
  input[type="text"], input[type="email"], input[type="tel"] {
    font-family: 'Russo', sans-serif !important;
    color: #800000;
    text-align: center;
    width: 100%;
    padding: 1%;
    margin: 1% 1% 0% 1%;
    border-radius: 30px; /* Similar to button style */
    border: 2px black solid;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); /* Adding box shadow */
    font-size: 16px;
    background-color: #f9f9f9; /* Matching background */
  }
  
  input[type="text"]::placeholder, 
  input[type="email"]::placeholder, 
  input[type="tel"]::placeholder {
    color: #80000091;
    text-align: center;
      opacity: 1; /* Ensures that the color is not faded (default can be semi-transparent) */
  }
  
  
  input[type="text"]:focus, input[type="email"]:focus, input[type="tel"]:focus {
    outline: none;  
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); /* Adding box shadow */
  
    border: 2px solid #000000;
  }
  
  
  button[type="submit"]:hover{
    background-color: #4e4e4e;
    color: #c80000;
    border: 2px solid #000000;
  
  }
  /* END ================================ Offer  part ================================== */
  /* Start ================================ App links  part ================================== */
  .app-link-sws {
    margin-top: -20% !important;

    grid-row: 8; /* Position in the grid */
    grid-column: 1 / -1; /* Span across all columns */
    display: grid;
    grid-template-rows: 30% 20% 50% ; /* Two rows, each taking half the space */
    grid-template-columns: 50% 50%; /* Two columns, each taking half the space */
  }
  
  /* Row 1, Col 1: Title */
  .app-link-sws h2 {
    align-self: end; /* Stick the title to the bottom */
    grid-row: 1; /* Position in the grid */
    grid-column: 1/-1; /* First column */
    font-size: 2rem; /* Title font size */
    text-align: center; /* Center the title */
    color: #000; /* Change color if needed */
  }
  
  /* Row 2, Col 1: Email form and download button */
  .email-download-form {
    grid-row: 2; /* Position in the grid */
    grid-column: 1/-1; /* First column */
    display: flex; /* Flexbox for horizontal alignment */
    justify-content: center; /* Align children to the right */
    align-items: center; /* Center items vertically */
    height: 20%; /* Height of the form */
    margin: 5%; /* Margin from the left */
    gap: 1vw;
    margin-top: 15%;
  }
  
  .email-download-form input {
    padding: 1vh; /* Input padding */
    font-size: 1rem; /* Input font size */
    border-radius: 0; /* No rounding */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.691); /* Box shadow for input */
    border: none; /* Remove border */
  }
  
  .email-download-form button {
    padding: 1vh 2vh; /* Button padding */
    font-size: 1rem; /* Button font size */
    background-color: #960000; /* Button background color */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.691); /* Box shadow for button */
    color: white; /* Button text color */
    border: none; /* Remove border */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  .email-download-form button:hover {
    background-color: #4e4e4e; /* Button hover color */
  }
  
  /* Row 1, Col 2: Android download */
  .android-link {
    grid-row: 3; /* Position in the grid */
    grid-column: 1; /* Second column */
    display: flex; /* Flexbox for vertical alignment */
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Align to the bottom */
    align-items: center; /* Center items horizontally */
    height: 20%; /* Use full height */
    text-decoration: none; /* Remove underline from link */
    margin-top: 8vh;

  }
  
  .android-link img {
    width: 90%; /* Image size */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 1vh; /* Space below the image */
    border: none; /* Remove border */
    background: none; /* Ensure no background is applied */
    -webkit-filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
    filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
    box-shadow: none;
    margin-top: 12%;
  
  }
  
  .android-link p {
    height: 100%;
    font-size: 1rem; /* Text size */
    color: #000; /* Text color */
    text-align: center; /* Center the text */
  }
  
  /* Row 2, Col 2: iOS download */
  .ios-link {
    grid-row: 3; /* Position in the grid */
    grid-column: 2; /* Second column */
    display: flex; /* Flexbox for vertical alignment */
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Align to the top */
    align-items: center; /* Center items horizontally */
    height: 20%; /* Use full height */
    text-decoration: none; /* Remove underline from link */
    margin-top: 10vh;
  }
  
  .ios-link img {
    margin-top: 15%;
    width: 90%; /* Image size */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 1vh; /* Space below the image */
    border: none; /* Remove border */
    background: none; /* Ensure no background is applied */
    -webkit-filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
    filter: drop-shadow(5px 5px 5px #222); /* Drop shadow for image */
    box-shadow: none;
  }
  
  .ios-link p {
    height: 100%;
    font-size: 1rem; /* Text size */
    color: #000; /* Text color */
    text-align: center; /* Center the text */
  }
  
  
  .message-sending-animation {
    width: 50px;
    height: 50px;
    position: relative;
    margin: 20px auto; /* Center the animation */
    animation: sendMail 2s infinite ease-in-out;
  }
  
  @keyframes sendMail {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-100px); /* Moves the envelope up */
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Envelope graphic */
  .message-sending-animation:before {
    content: '✉️'; /* Envelope emoji */
    font-size: 50px;
    display: block;
    text-align: center;
  }


.logo {
  filter: drop-shadow(5px 5px 5px #222);
  grid-column: 1 / -1;
  grid-row: 9;
  position: relative;
  margin-bottom: 100%;
  bottom: 20% !important;
  width: 70%;
}

 
  
  /* END ================================ App links  part ================================== */
  
  /* END ================================ Shop container  ================================== */
  
  
  .size-range-container-din50{
    display: flex;
  }

.size-range-container-din50 h2 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center; /* Center the heading */
  margin-bottom: 0px; /* Spacing below the heading */
  color: #000000; /* Adjust as needed */
  grid-row: 1;
  height: 2%;
  font-size: x-large;
}
.size-range-container-din50 h3 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center; /* Center the heading */
  margin-bottom: 10px; /* Spacing below the heading */
  color: #000000; /* Adjust as needed */
  grid-row: 1;
  height: 2%;
  font-size: large;
}
.size-range-container-din50 h4 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center; /* Center the heading */
  margin-bottom: 10px; /* Spacing below the heading */
  color: #000000; /* Adjust as needed */
  grid-row: 1;
  height: 2%;
  font-size: medium;
}

.size-range-container-smartbox-only{
   display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left; /* Ensures text stays centered */
  grid-column: 1 / -1; /* Ensures it spans full width */
  background-color: #cccccc;
  border: 2px solid #929292;
  border-radius: 10px;
  padding: 20px;
  height: auto; /* Allow it to adjust dynamically */
  width: 95%;
  margin: 0 auto; /* Centers horizontally */
  gap: 2vh;
}

.size-range-container-smartbox-only h2 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center; /* Center the heading */
  margin-bottom: 20px; /* Spacing below the heading */
  color: #000000; /* Adjust as needed */
  grid-row: 1;
  height: 2%;
  margin: 4%;
  font-size: x-large;
}
.size-range-container-smartbox-only h3 {
  grid-column: 1 / -1; /* Span across all columns */
  text-align: center; /* Center the heading */
  margin-bottom: 20px; /* Spacing below the heading */
  color: #000000; /* Adjust as needed */
  grid-row: 1;
  height: 10%;
  margin: 4%;
  font-size: large;
}

.price_a-smartbox{

    font-size: 4vw;
    font-style: italic;
    color: white;
  background-color: #960000;
  border-radius: 10px;
  grid-column: 1/-1;
  grid-row: 4;
  padding: 4%;
  margin: 3%;
  width: 90%;
  text-align: center;
  margin-right: 4%;
  margin-top: 15%;
  box-shadow: 2px 6px 6px rgba(0, 0, 0, 0.847); /* Box shadow for button */
  
  
}

}