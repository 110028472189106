.blog-container {
    display: grid;
    grid-template-columns: 20% 60% 20%;  /* 3 columns: 20% for menu, 60% for content, 20% for empty space */
    background-color: #2e2e2e;
    color: white;
    margin: 0;
    padding: 0;
    font-family: 'Russo', sans-serif;
  height: auto;/* Ensure the container spans the full viewport height */
}

.blog-container h2 {
    font-size: medium;
}

.menu ul li .a_active {
    background-color: #f1c40f;
    color: #2e2e2e;
}

.menu {
    background-color: transparent;
    padding: 20px;
    display: flex;
    flex-direction: column;

}

.menu ul {
    padding: 0;
    font-size: x-small;
}

.menu ul li {
    list-style-type: none;
    margin: 10px 0;
}

.menu ul li a {
    text-decoration: none;
    color: white;
    padding: 10px;
    display: block;
    background-color: rgb(106, 0, 0);
    border: 1px solid black;
    text-align: center;
    border-radius: 4px;
}

.menu ul li a:hover {
    background-color: #f1c40f;
    color: #2e2e2e;
}

.blog-content {
    background-color: #1c1c1c;

    /*overflow-y: auto; /* Add scroll if content is too long */
}

.empty-space {
    /* Empty space for future use */
}

.bela {
    margin-top: -4.5%;
    font-size: larger;
    margin-bottom: 20px;
    color: #f1c40f;
    display: block;
  
}

p, ul {
    font-size: small;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: justify;
}

ul li {
    margin-bottom: 10px;
    font-size: small;
}

strong {
    color: #9e1000;
    font-size: small;
}

.author_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1%;
    margin-top: 20px;
}

.author-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid white;
    margin-top: -25px;
}

.author-text {
    margin-top: -20px;
    margin-left: 10px;
}

.welding-image_his {
    padding-left: 20%;
    width: 60%;
    height: auto;
    margin-top: 20px;
}

.facebook-share-button {
    display: inline-block;
    padding: 2%;
    background-color: #4267B2; /* Facebook kék szín */
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    border-radius: 4px;
    margin-top: -3%;
    margin-left: 16%;
    transition: background-color 0.3s;
  }
  
  .facebook-share-button:hover {
    background-color: #365899;
  }

  /* @media lekérdezés a mobil nézethez */
@media (max-width: 767px) {
    .blog-container {
        grid-template-columns: 1fr; /* Egy oszlop, amely az egész rendelkezésre álló szélességet lefedi */
        padding: 10px; /* Némi belső margó, hogy a tartalom ne érjen közvetlenül a szélekhez */
        height: auto;
    }

    .menu {
        grid-column: 1 / -1; /* A menü teljes szélességben jelenjen meg mobilon */
        padding: 10px;
    }

    .blog-content {
        grid-column: 1 / -1; /* A tartalom is teljes szélességben jelenjen meg mobilon */
        padding: 10px;
    }

    .welding-image_his {
        padding-left: 0;
        width: 100%;
        height: auto;
        margin-top: 10px;
    }

    .bela {
        margin-top: 20%;
        font-size: larger;
        margin-bottom: 2px;
        color: #f1c40f;
        display: block;
        padding: 210px 10px 10px 10px; /* Növeld a szülő elem tetején lévő paddingot */
    }

      .author_div {
        display: flex !important;
        flex-direction: row !important;
        align-items: center !important; /* Vertically center items */
        justify-content: flex-start !important; /* Align items to the start (left) */
        padding: 1% !important;
        margin-top: 20px !important;
        width: 100%; /* Ensure it takes full width */
    }



    .author-text {
        margin-top: -20px !important;
        margin-left: 10px !important;
        text-align: left !important; /* Ensure text is aligned to the left */
        font-size: 14px !important; /* Set a specific font size */
        width: 80%; /* Adjust width as needed */
    }

    .author-title {
        text-align: left !important;
        font-size: small !important; /* Ensure font size is small */
        color: #ffffff; /* Add a color for better visibility */
        margin-top: 5px; /* Add some spacing */
    }
}