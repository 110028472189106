.shop-container {
    text-align: center;
    padding: 20px;
    background-color: #2e2e2e;
    font-family: 'Russo', sans-serif;
    height: 100vh;
  }
  
.BASZODJMEG {
  margin-top: 0%;
    display: grid;
    grid-template-columns: 30% 40% 30%; /* 3 oszlop: első fix, második rugalmas, harmadik fix */
    align-items: center; /* Középre igazítás vertikálisan */
    max-width: 100%;
}

.button-back {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%; /* Fix méret */
    height: 50%;
    font-size: 50px;
    background-color: white;
    border-radius: 15px;
    grid-column: 1;
    margin-left: 15%;
    margin-bottom: 12% !important;
}

.kibaszott-shop-button-text{
    color: #960000;
    font-family: 'Russo', sans-serif;
    padding-right: 5%;
    justify-content: center;
    text-align: center;
    font-size: 2.5vh;
}

.contact-button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%; /* Fix méret */
    height: 50%;
    background-color: white;
    border-radius: 15px;
    grid-column: 3;
    margin-left: 10% !important;
    margin-bottom: 12% !important;
    border: none;
    cursor: pointer;
}


.contact-icon {
    font-size: 12px; /* Ikon kisebb mérete */
}
  
  .logo {
    height: 50px;
  }
  
.jakab {
    font-size: 1.5vw;
    color: #960000;
    margin-bottom: 10%;
    background-color: white;
    border-radius: 20px;
    margin-left: 15%;
    width: 60%;
}





.button-back:hover {
    background-color: #d2d2d2;
    color: #ae0000;
}

.button-back:active {
    background-color: #494949;
    color: #960000;
}




  .products {
    display: flex;
    justify-content: space-between;
    margin: -10px 0;
    
  }
 
  .product-card {
    background-color: white;
    border: 4px solid #000000;
    border-radius: 10px;
    padding: 20px;
    width: 30%;
    margin: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .product-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
      -webkit-filter: drop-shadow(5px 5px 5px #000000); /* Drop shadow for image */
  filter: drop-shadow(5px 5px 5px #000000); /* Drop shadow for image */
  }
  
  .product-info {
    text-align: left;
  }
  
  .product-price {
    font-weight: bold;
    color: #000;
  }

.close-button{
  background-color: #960000;
  color: #ffffff;
  font-family: 'Russo', sans-serif;
  border-radius: 10px;
  width: 120px !important;
  height: 40px !important;
}

.close-button:hover {
    background-color: #d2d2d2;
    color: #ae0000;
    cursor: pointer;
}

.close-button:active {
    background-color: #494949;
    color: #960000;
    cursor: pointer;
}

.shopForm{
  margin: 10px;
  padding: 10px;
  gap: 20px;
}

.dialog {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}



button[type="submit"] {
  margin-top: 10px;
}



@media (max-width: 762px) {

  .shop-container{
    height: auto;
    margin-top: 12%;
    padding: 0;
  }

  .products {
    flex-direction: column; /* Overrides desktop with vertical layout */
    justify-content: center;
    align-items: center;
    padding-top: 0%;
    margin-top: 0%;
  }

  .product-card {
    background-color: white;
    border: 4px solid #000000;
    border-radius: 30px;
    padding: 5%;
    width: 70%;
    margin: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  .product-image {
    width: 100%;
    height: auto;
    margin-bottom: 0px;
  }
  
  .product-info {
    text-align: center;
    
  }
  
  .product-price {
    font-weight: bold;
    color: #000;
    margin-bottom: 2%;
  }



  .BASZODJMEG-MOBILON-IS {
    margin-top: 8vh;
    display: grid;
    grid-template-columns: 20% auto 20%; /* 3 oszlop: első fix, második rugalmas, harmadik fix */
    align-items: center; /* Középre igazítás vertikálisan */
  }

  .button-back-baszodjmeg {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%; /* Fix méret */
      height: 30%;
      font-size: 50px;
      background-color: white;
      border-radius: 10px;
      grid-column: 1;
      margin-left: 20% !important;
      margin-top: 50% !important;
  }


  .contact-button-baszodjmeg {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%; /* Fix méret */
      height: 30%;
      background-color: white;
      border-radius: 10px;
      grid-column: 3;
      margin-left: 10% !important;
      margin-bottom: -50% !important;
      border: none;
      cursor: pointer;
  }

  .contact-icon {
      font-size: 12px; /* Ikon kisebb mérete */
  }


  .jakab-mobile {
      grid-column: 2;
      text-align: center; /* Ha középre akarod a szöveget */
      font-size: 1.75vh !important;
      width: 90%;
      height: 30%;
      margin-top: 18%;
  }


    p, ol {
      font-size: small;
      line-height: 1.5;
      margin-bottom: 20px;
      text-align: center;
  }
}
  

  
  .product-card:hover {
    transform: scale(1.05);
  }

  .product-card:active {
    transform: scale(1.2);
    background-color: #e0e0e0;
  }
  


  
  .more-products-button:hover {
    background-color: #f3f3f3;
  }
  