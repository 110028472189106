:root {
  --background-light: #2e2e2e;
  --background-darker: #1c1c1c;
  --red-light: #960000;
  --red-darker: #800000;
  --image-shadow: drop-shadow(5px 5px 5px #000000);
}


.about-container {
  display: flex;
  width: 100%;
  height: 90vh;
  border: 5px black solid ;
}

.about-column-a {
  width: 60%;
  background-color: #000;
  padding: 20px;
  flex-direction: row;
  display: flex;
}


.A-column-a {
  width: 33%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.A-row-a{
  flex-direction: column;
  display: flex;
  justify-content: center;
  height: 50%;
  width: 100%;
  align-items: center;
  padding: 20px;
  -webkit-filter: drop-shadow(5px 5px 5px #000000); /* Drop shadow for image */
  filter: drop-shadow(5px 5px 5px #000000); /* Drop shadow for image */
}

.A-row-b{
  flex-direction: column;
  display: flex;
  justify-content: center;
  height: 33%;
  width: 100%;
  align-items: center;
  -webkit-filter: drop-shadow(5px 5px 5px #000000); /* Drop shadow for image */
  filter: drop-shadow(5px 5px 5px #000000); /* Drop shadow for image */
}


.about-column-b {
  width: 40%;
  background-color: #2e2e2e !important;
  padding: 20px;

}
.about-container {
  display: flex;
  width: 100%;
}

.about-column-a {
  width: 60%;
  background-color: #2e2e2e;
  padding: 20px;
}

.rotate-image {
  transform: rotate(90deg);
  margin-top: 85%;
}

.A-row-a a{
  margin-top: 10px;
  color: #fff;
  font-family: 'Russo', sans-serif !important;
}

.A-row-b a{
  margin-top: 10px;
  color: #fff;
  font-family: 'Russo', sans-serif !important;
}

/* 40% os rész */

.about-column-b {
  width: 40%;
  background-color: #fff;
  padding: 0px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.about-column-b-title {
  font-family: 'Russo', sans-serif !important;
  color: #960000;
  background-color: white;
  position: relative;
  border-radius: 15px;
  border: 2px black solid;
  text-align: center;
  justify-content: center; /* Vízszintesen középre */
  align-items: center; /* Függőlegesen középre */
  display: flex;
  margin: 0;
  height: 8vh;
  font-size: large;
}


.Title-container {
  margin-top: 40px;
  height: auto;
}

.description{
  margin-top: 20px;
  background-color: #ffffff;
  width: 100%;
  height: 80%;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 10px #000;
}

.description p{
  color: #000000;
  font-family: 'Russo', sans-serif !important;
  text-align: justify;
  font-size: 2.0vh !important;
  padding: 20px 50px 0px 50px ;
  text-justify: inter-word;
}

.description img{
  width: 70% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2%;
  -webkit-filter: drop-shadow(5px 5px 5px #000000); /* Drop shadow for image */
  filter: drop-shadow(5px 5px 5px #000000); /* Drop shadow for image */
}

.buttons{
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.buttons button{
  font-family: 'Russo', sans-serif !important;
  background-color: #000;
  padding: 10px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px; 
}

.buttons button:hover{
  background-color: #fff;
  color: #000;
  border: 2px solid #000;
}

.left-button {
  float: left;
}

.right-button {
  float: right;
}

@media screen and (max-width: 767px) {
  .about-container-mobile {
    background-color: white !important;
    border: 5px solid #000 !important;
    padding-right: 2% !important;
    padding-left: 2% !important;
  }

  .about-column-b-title-mobile {
    font-family: 'Russo', sans-serif !important;
    color: #960000;
    background-color: white;
    border-radius: 15px;
    border: 2px black solid ;
    text-align: center;
    margin: 0;
    margin-top:2.5vh;
        grid-row: 1; /* Adjusted to 1 (grid rows start at 1) */
      font-size: 3vh !important;
      height: 6vh;
      width: 80vw;
          /* Center vertically and horizontally */
    display: flex;
    justify-content: center; /* Horizontal alignment */
    align-items: center; /* Vertical alignment */
}

  .about-column-b-mobile {
    height: auto;
    display: grid;
    grid-template-rows: 15% 40% 35% 10%;
    width: 100%;
    height: 75vh;
    padding: 10px;
    background: white !important;
  }



  .description-mobile {
    grid-row: 2;
    z-index: 9999;
    color: #000 !important;
    font-family: 'Russo', sans-serif !important;
    display: flex;
    justify-content: center; /* Horizontal alignment */
    align-items: center; /* Vertical alignment */
  }

.about-image-mobile {
    grid-row: 3;
    width: 80%;
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center horizontally */
    border-radius: 1vh;
    object-fit: contain; /* Better for image scaling */
    /*border: 0.1vh solid #000000; /* Reduced border thickness */
    cursor: pointer;
    display: block; /* Reset from flex to default block */
      -webkit-filter: drop-shadow(5px 5px 5px #000000); /* Drop shadow for image */
  filter: drop-shadow(5px 5px 5px #000000); /* Drop shadow for image */
}


  .buttons-mobile {
    display: grid;
    grid-template-columns: 25% auto 25%; /* Defines columns for buttons */
    grid-row: 4; /* Ensure buttons occupy the fourth row */
  }

  .left-button-mobile {
    grid-column: 1; /* Place on the first column of the buttons grid */
  }

  .right-button-mobile {
    grid-column: 3; /* Place on the third column of the buttons grid */
  }

  .buttons-mobile button{
  font-family: 'Russo', sans-serif !important;
  background-color: #000;
  padding: 10px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px; 
  width: 100%;
}
}
