:root {
    --background-light: #2e2e2e;
    --background-darker: #1c1c1c;
    --red-light: #960000;
    --red-darker: #800000;
    --image-shadow: drop-shadow(5px 5px 5px #000000);
    --button-light: #d2d2d2;
    --button-dark: #494949;
  }
  

.welding-page-container {
    text-align: center;
    background-color: var(--background-light);
    color: #333;
    font-family: Arial, sans-serif;
    height: 90vh;
    display: flex;
    flex-direction: column;
    border-left: #000 5px solid;
    border-right: #000 5px solid;
    border-bottom: #000 5px solid;
}

h1 {
    font-family: 'Russo', sans-serif;
    font-size: 4vh;
    font-weight: normal;
    color: #fff;
    background-color: #000;
    padding: 2vh 4vh; /* Csökkentett padding */
    text-align: center;
    margin: 0 auto;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 0 0 8vh 8vh; /* Lekerekítés arányosítása */
    box-shadow: 0.2vh 0.2vh 1vh rgba(0, 0, 0, 0.5);
}

.gallery-container {
    background-color: var(--background-light);
    flex: 0 0 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5vh; /* Csökkentett távolság a képek között */
    width: 60%;
}

.gallery-image {
    width: 100%;
    border-radius: 1vh;
    object-fit: contain;
    border: 0.1vh solid #000000; /* Csökkentett keret vastagság */
    cursor: pointer;
    -webkit-filter: drop-shadow(2px 2px 2px #ffffff);
    filter:drop-shadow(1px 1px 10px  #ffffff) ;
}

.prev-btn, .next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    -webkit-filter: drop-shadow(2px 2px 2px #ffffff);
    filter:drop-shadow(1px 1px 10px  #ffffff) ;
}

.prev-btn {
    left: calc(50% - 70vh);
    transform: scaleX(-1);
    margin-top: -3vh;
}

.next-btn {
    right: calc(50% - 70vh);
}

.prev-btn img, .next-btn img {
    width: 5vh; /* Csökkentett gomb méret */
    height: 5vh;
}

.form-section {
    display: flex;
    height: 100%;
    background-color: var(--background-light);
    color: white;
    align-items: stretch;
    justify-content: center;
    padding: 0;
    margin: 0;
    border-top: #000 5px solid;
    border-bottom: #000 5px solid;

}

.form-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh; /* Csökkentett padding a kép körül */
}

.form-image img {
    margin: 5%;
    width: 90%;
    height: auto;
    object-fit: contain;
    border-radius: 20px !important;
    top: 0;
    transform: none;
    -webkit-filter: var(--image-shadow);
    filter: var(--image-shadow);
}

.form-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1vh; /* Csökkentett padding */
  

}

.form-container h2 {
    font-family: 'Russo', sans-serif;
    font-size: 3vh;
    color: #fff;
    margin-bottom: 0vh;
}

.form-container p {
    font-family: 'Russo', sans-serif;
    font-size: 2vh;
    color: #fff;
    margin-bottom: 0vh;
}

form {
    margin-top: 0vh;
}

form input {
    width: calc(50% - 0.5vh);
    padding: 0.5vh; /* Csökkentett padding */
    border: 0.1vh solid #ccc;
    border-radius: 0.5vh;
    background-color: #ffffff;
    color: #000000;
}

form button {
    color: var(--red-darker);
    width: 50%;
    margin-top: 1.5vh;
    padding: 1vh 0;
    background-color: white;
    font-family: 'Russo', sans-serif !important;
    font-size: 2.5vh;
    border: none;
    border-radius: 1.5vh;
    cursor: pointer;
    text-align: center;
}

form button:hover {
    background-color: var(--button-light) !important;
    border: 0.1vh solid black !important;
    color: var(--red-light) !important;
}

form button:active {
    background-color: var(--button-dark);
    border: 0.1vh solid black !important;

}

.lightbox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    border: 0.4vh solid #FFF;
    border-radius: 2vh;
}

.lightbox-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 2vh;
}

.lightbox-close {
    position: absolute;
    top: 1.5vh;
    right: 3vh;
    color: white;
    font-size: 3.5vh;
    font-weight: bold;
    cursor: pointer;
}

/* @media lekérdezés a mobil nézethez */
@media (max-width: 767px) {
    .welding-page-container {
        text-align: center;
        background-color: #fff;
        color: #333;
        font-family: Arial, sans-serif;
        height: 100vh;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    
    h1 
    {
        font-family: 'Russo', sans-serif !important;
        font-weight: bold;
        color: #960000;
        background-color: white;
        border-radius: 15px;
        border: 2px black solid ;
        margin: 0;
        margin-top:2.5vh;
            grid-row: 1; /* Adjusted to 1 (grid rows start at 1) */
        font-size: 2.5vh !important;
        height: 6vh;
        width: 80vw;
            /* Center vertically and horizontally */
        display: flex;
        text-align: center;
        justify-content: center; /* Horizontal alignment */
        align-items: center; /* Vertical alignment */
        font-family: 'Russo', sans-serif !important;
        margin-left: 5%;
        margin-right: 20%;
    }
    
    .gallery-container {
        background-color: #ffffff;
        flex: 0 0 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    
    .gallery-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1vh; /* Csökkentett távolság a képek között */
        width: 80%;
    }
    
    .gallery-image {
        width: 100%;
        border-radius: 1vh;
        object-fit: contain;
        border: 0.1vh solid #000000; /* Csökkentett keret vastagság */
        cursor: pointer;
    }
    
    .prev-btn, .next-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
    }
    


    .prev-btn {
        left: calc(0% );
        transform: scaleX(-1);
        margin-top: -2vh;
        margin-left: 1%;
    }
    
    .next-btn {
        right: calc(0%);
        margin-right: 1%;

    }
    
    .prev-btn img, .next-btn img {
        width: 4vh; /* Csökkentett gomb méret */
        height: 4vh;
    }
    
    .form-section {
        display: flex;
        height: 80%;
        background-color: white;
        color: white;
        padding: 0;
        margin: 0;
        flex-direction: column;
        width: 100%;
    }
    
    .form-image {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1vh; /* Csökkentett padding a kép körül */
    }
    
    .form-image img {
        width: 70%;
        height: auto;
        object-fit: contain;
        border-radius: 20px;
        top: 0;
        transform: none;
    }
    
    .form-container {
        flex: 1;
        display: flex;
        flex-direction: column;
 
        padding: 1vh; /* Csökkentett padding */
    }
    
    .form-container h2 {
        font-family: 'Russo', sans-serif;
        font-size: 2.5vh;
                color: #960000;

        margin-bottom: 1vh;
    }
    
    .form-container p {
        font-family: 'Russo', sans-serif;
        font-size: 2vh;
        color: #960000;
        margin-bottom: 0vh;
    }
    
    form {
        margin-top: 1.5vh;
    }
    
    form input {
        width: calc(60%);
        padding: 0.5vh; /* Csökkentett padding */
        border: 0.1vh solid #ccc;
        border-radius: 1vh;
        background-color: #ffffff;
        color: #000000;
    }
    
    form button {
        width: 80%;
        margin-top: 0vh;
        padding: 1vh;
        background-color: #960000;
        color: white;
        font-family: 'Russo', sans-serif !important;
        font-size: 2vh;
        border: none;
        border-radius: 1.5vh;
        cursor: pointer;
        text-align: center;
        border: 0.3vh solid black;
    }
    
    form button:hover {
        background-color: #000;
        border: 0.1vh solid #fff;
        color: #fff;
    }
    
    .lightbox {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 50%;
        background-color: rgba(0, 0, 0, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        border: 0.4vh solid #FFF;
        border-radius: 2vh;
    }
    
    .lightbox-image {
        max-width: 100%;
        max-height: 100%;
        border-radius: 2vh;
    }
    
    .lightbox-close {
        position: absolute;
        top: 1.5vh;
        right: 3vh;
        color: white;
        font-size: 3.5vh;
        font-weight: bold;
        cursor: pointer;
    }
    
  }
