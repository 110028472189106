
.menu-conatiner{
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 15vh;
}

  /* Mobil nézetben rejtett menüelemek */
  @media only screen and (max-width: 767px) {


.menu-conatiner{
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    height: 12vh;
}

/* Alapértelmezett stílusok a HamburgerMenu komponenshez */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 20px;
    top: 35px;
    /* Így az ikon tükrözve jelenik meg */
    transform: scaleX(-1);
    left: auto;
    right: 40px;
  }
  
  .bm-burger-bars {
    background: #fff;
  }
  
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  .bm-cross {
    background: #bdc3c7;
  }
  
  .bm-menu {
    background: #000;
    padding: 2em 1em 0;
    font-size: 1em;
    /* A lenyíló menü fentről indul */
    top: 0;
    bottom: unset;
    margin-top: -10px;

  }
  
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    /* A menü elől látszódjon, ha kinyílik */
    z-index: 102;
  }
  
  .bm-item-list {
    color: #FFF;
    padding: 0.8em;
  }

  .bm-item-list a {
    color: #FFF;
    text-decoration: none;
    display: block;
    padding: 15px;
    font-family: 'Russo', sans-serif !important;
    text-align: center;
  }
  
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  
  /* Testre szabás: hozzáadhatod az egyedi stílusokat és elhelyezkedéseket */
  .menu-item {
    display: block;
    padding: 10px;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-family: 'Russo', sans-serif;
    transition: background-color 0.3s ease;
  }
  
  .menu-item:hover {
    background-color: #555;
  }

  /* Logo stílusai */
.logo-container {
    display: flex;
    padding: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  
  .menu-logo {
    width: 100px; /* Állítsd be a megfelelő méretet */
    height: 100px;
  }

    .menu-item {
      display: none;
    }
  }

  .navbar-button-lang-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    
  }
  