:root {
  --background-light: #2e2e2e;
  --background-darker: #1c1c1c;
  --red-light: #960000;
  --red-darker: #800000;
  --image-shadow: drop-shadow(5px 5px 5px #000000);
  --button-light: #d2d2d2;
  --button-dark: #494949;
}

.welding-machines-title{
    font-family: 'Russo', sans-serif !important;
  color: #960000;
  background-color: white;
  position: relative;
  border-radius: 15px;
  border: 2px black solid;
  text-align: center;
  justify-content: center; /* Vízszintesen középre */
  align-items: center; /* Függőlegesen középre */
  display: flex;
  margin: 0;
  margin-top: 1%;
  
  padding: 0.1% 2% ;
  height: 10vh;
  font-size: xx-large;
}

.page-container_ {
  text-align: center;
  padding: 0px;
  background-color: var(--background-light);
  color: #ffffff;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: #000 solid 5px;
}
.page-container_ h1 {
  margin-top: 0vh;
  font-family: 'Russo', sans-serif !important;
  font-size: 5vh; /* Betűméret függőleges mérethez igazítva */
  text-align: center;
  align-self: center;
}

.cards-container_ {
  margin-top: 4vh !important;
  display: grid;
  grid-template-columns: repeat(3, 25vw); /* Oszlop szélesség függőleges mérethez arányosan */
  column-gap: 5vh; /* Távolság a kártyák között függőlegesen arányosan */
  row-gap: 4vh; /* Távolság a sorok között függőlegesen arányosan */
  justify-content: center;
  margin: 0 auto;
}

.card_ {
  width: 25vw; /* Szélesség függőleges mérethez arányosan */
  height: 25vh; /* Magasság függőleges mérethez arányosan */
  perspective: 1000px;
  margin: 0 auto;

  border-radius: 20px;
}
.card_ p {
  font-family: 'Russo', sans-serif !important;
  font-size: 3vh; /* Betűméret függőleges mérethez arányosan */
  color: #000000;
  margin: 0;
  margin-bottom: 0px;
}

.card-inner_ {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border: 4px solid black;
  border-radius: 20px;
}

.card_:hover .card-inner_ {
  transform: rotateY(180deg); /* Forgatás az Y-tengely körül */
}

.card-front_, .card-back_ {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Az elem hátulja nem látható */
  border-radius: 16px; /* A kártya lekerekítése */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-front_ {
  background-color: #ffffff; /* Fehér háttér */
}

.card-back_ {
  background-color: #fff; /* Kék háttér */
  transform: rotateY(180deg); /* A hátlap kezdetben el van forgatva */
}

.card_ p {
  font-family: 'Russo', sans-serif !important; /* Russo One betűtípus */
  font-size: 25px; /* 32px betűméret */
  color: #000000; /* Fekete szín */
  margin: 0;
  margin-bottom: 0px; /* Fix távolság az alsó széltől */
}

.card_ img {
  max-width: 80%;
  max-height: 70%;
  border-radius: 8px;
  -webkit-filter: drop-shadow(0px 5px 0px #000000); /* Drop shadow for image */
  filter: drop-shadow(0px 5px 5px #000000); /* Drop shadow for image */
}

.load-more-btn {
  width: 50vw; /* Szélesség függőleges mérethez arányosan */
  margin-top: 3vh;
  padding: 1.5vh 0;
  background-color: white;
  color: var(--red-darker);
  font-family: 'Russo', sans-serif !important;
  font-size: 3vh; /* Betűméret függőleges mérethez arányosan */
  border: 2px solid black;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.load-more-btn:hover {
  background-color: var(--button-light);
  border: 2px solid black;
  color: var(--red-light);

}

.load-more-btn:active {
  background-color: var(--button-dark);
}




@media (max-width: 767px) {

  .split-screen{
    background: white;
    border: #000 5px solid;
    border-top: none;
    width: 100%;
    height: 90vh !important;
    display: flex;
    flex-direction: column;
  }


  .page-container {
      text-align: center;
      padding: 0px;
      background-color: #ffffff;
      color: #ffffff;
      height: 90vh;
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  .page-container h1 {
      margin-top: 2vh;
      font-family: 'Russo', sans-serif !important;
      font-size: 5vh; /* Betűméret függőleges mérethez igazítva */
      text-align: center;
      align-self: center;
  }

  .cards-container {
      display: grid;
      grid-template-columns: repeat(3, 25vw); /* Oszlop szélesség függőleges mérethez arányosan */
      column-gap: 5vh; /* Távolság a kártyák között függőlegesen arányosan */
      row-gap: 4vh; /* Távolság a sorok között függőlegesen arányosan */
      justify-content: center;
      margin: 0 auto;
  }

  .card {
      width: 25vw; /* Szélesség függőleges mérethez arányosan */
      height: 25vh; /* Magasság függőleges mérethez arányosan */
      perspective: 1000px;
      margin: 0 auto;
  }
  .card p {
      font-family: 'Russo', sans-serif !important;
      font-size: 3vh; /* Betűméret függőleges mérethez arányosan */
      color: #000000;
      margin: 0;
      margin-bottom: 0px;
  }

  .card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.6s;
      transform-style: preserve-3d;
  }

  .card:hover .card-inner {
      transform: rotateY(180deg); /* Forgatás az Y-tengely körül */
  }

  .card-front, .card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden; /* Az elem hátulja nem látható */
      border-radius: 16px; /* A kártya lekerekítése */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .card-front {
      background-color: #ffffff; /* Fehér háttér */
  }

  .card-back {
      background-color: #fff; /* Kék háttér */
      transform: rotateY(180deg); /* A hátlap kezdetben el van forgatva */
  }

  .card p {
      font-family: 'Russo', sans-serif !important; /* Russo One betűtípus */
      font-size: 32px; /* 32px betűméret */
      color: #000000; /* Fekete szín */
      margin: 0;
      margin-bottom: 0px; /* Fix távolság az alsó széltől */
  }

  .card img {
      max-width: 80%;
      max-height: 80%;
      border-radius: 8px;
  }

  .load-more-btn {
      width: 50vw; /* Szélesség függőleges mérethez arányosan */
      margin-top: 3vh;
      padding: 1.5vh 0;
      background-color: white;
      font-family: 'Russo', sans-serif !important;
      font-size: 3vh; /* Betűméret függőleges mérethez arányosan */
      border: none;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
  }

  .load-more-btn:hover {
      background-color: #000;
      border: 2px solid #fff;
      color: #fff;
  }


    
  .top-pane {
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start; /* Ahelyett, hogy középre tolná a tartalmat */
      border-radius: 20px;
      margin: 10px;
      padding-bottom: 0; /* Ha padding van, lehet, hogy felesleges helyet ad */
  }

    .top-pane-title{
      height: 10%;
      
      align-items: center;
    }
    
    .top-pane-title h2{
      font-family: 'Russo', sans-serif !important;
      color: #960000;
      background-color: white;
      border-radius: 15px;
      border: 2px black solid ;
      text-align: center;
      margin: 0;
      margin-top:2.5vh;
          grid-row: 1; /* Adjusted to 1 (grid rows start at 1) */
        font-size: 3vh !important;
        height: 6vh;
        width: 80vw;
            /* Center vertically and horizontally */
      display: flex;
      justify-content: center; /* Horizontal alignment */
      align-items: center; /* Vertical alignment */
      font-family: 'Russo', sans-serif !important;
    }
    
  .top-pane-img {

    margin-top: 10%;
    margin-bottom: -15%;
  }

  .top-pane-img img {
    padding: 3%;
    margin: 4vh auto; /* Vertically and horizontally center */
    border-radius: 20px;
    height: 80%;
    object-fit: contain;
    cursor: pointer;
    display: block; /* Ensure the image is treated as a block element */
    -webkit-filter: drop-shadow(5px 5px 5px #000000);
    filter: drop-shadow(5px 5px 5px #000000);

  }
    
    .bottom-pane {
      flex: 20%;
      display: flex;
      flex-direction: row;
      margin: 2px;
    }
    
    .bottom-pane-arrow{
      height: 100%;
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .bottom-pane-arrow img{
      height: 30%;
      width: 100%;
      margin: 2px;
    }
    .bottom-pane-middle {
      height: 100%;
      width: 70%;
      display: flex;
      justify-content: center; /* Horizontálisan középre igazítás */
      align-items: center; /* Vertikálisan középre igazítás */

  }

  .card-mobile {
      display: flex; /* A kártya tartalmának középre igazításához szükséges */
      flex-direction: column; /* A tartalom függőleges elrendezése */
      justify-content: center; /* A tartalom vertikális középre igazítása */
      align-items: center; /* A tartalom horizontális középre igazítása */
      width: 90%;
      height: 70% !important;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border: 2px solid black;
  }

  .card-content-mobile {
      display: flex; /* Flexbox a tartalom középre igazításához */
    margin-top: 10px;
      align-items: center; /* Vertikálisan középre igazítás */
      justify-content: center;
      flex-direction: column;
      width: 80%; /* A tartalom teljes szélességét elfoglalja */
      height: 80%; /* A tartalom teljes magasságát elfoglalja */
  }

  .card-content-image-mobile {
    max-width: 60%;
    max-height: 50%;
  }

  .card-title {

      color: #000;




      font-family: 'Russo', sans-serif !important;
  }

    
    .card-button-mobile {
      position: relative;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 6px;
      background-color: #000;
      color: #fff;
      border: none;
      border-radius: 0 0 6px 6px;
      cursor: pointer;
      font-size: 14px;
      font-family: 'Russo', sans-serif !important;
    }
    
    .card-button:hover{
      background-color: #fff;
      color: #000;
      border-top: 2px solid black;
    }
    
    .button-img-mobile {
      width: 10vw !important;  /* Set width relative to viewport */
      height: 10vw !important; /* Equal height to width */
      max-width: 100px; /* Optional: Prevents it from getting too big */
      max-height: 100px;
      aspect-ratio: 1 / 1; /* Ensures the element remains a square */
    }
}
    

