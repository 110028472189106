.container {
    display: flex;
    height: 90vh;; /* Teszt üzenet Mártonnak*/
  }
  
  .left-column {
    width: 67.5%;
    background-color: #fff;
  }

  .application-title{
    display: flex;
    justify-content: center;
  }

  .application-title h2{
    font-family: 'Russo', sans-serif !important;
    font-size: 30px;
  }

  .scrol_buttons{
    margin-top:  0px;
    display: flex;
    justify-content: center;
  }
  .content {
    flex: 1;
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 0.3s, transform 0.3s;
  }
  
  .slide-in {
    opacity: 1;
    transform: translateX(0);
  }

  .content-container {
    display: flex;
    flex: 1;
  }

  .button {
    background-color: transparent;
    color: #fff;
    background-color: #000;
    border: 2px solid #000;
    font-family: 'Russo', sans-serif !important;
    padding: 15px 75px;
    letter-spacing: 2px;
    font-size: 15px;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .button.active {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
  }
  
  .button.active {
    background-color: #fff;
    color: #000;
    border: 2px solid #000;
  }
  
  .button:hover {
    background-color: #fff;
    color: #000;
  }
  


  .div-setup-row{
    height: 100%;
    background-color: bisque;
  }

  .div-container {
    display: flex;

    height: 100%;
    width: 100%;
    overflow: auto; /* Hozzáadott overflow tulajdonság */
    background-color: a;
  }
  
  .div-content {
    max-width: 100%;
    margin: 0 auto;
    max-height: 100%;
  }
  
  

.setup-container{
    height: 80%;

}

.setup-div{
    height: 33%;

    display: flex;
    flex-direction: row;
    justify-content: center;
}

.setup-row{
    width: 33%;

    justify-content: center;
    align-items: center;
    display: flex;
}

.setup{
    background-color: #9A031E;
    height: 85%;
    width: 85%;
    border-radius: 10PX;
    border: 3PX SOLID #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.setup h2{
    font-family: 'Russo', sans-serif !important;
    color: #fff;
    letter-spacing: 1px;
    
}
.lekerdezes-cont{
    margin-top: 20px;
    height: 77%;
    width: 100%;
display: flex;
justify-content: center;
align-items: center;
}

.lekerdezes{
    width: 80%;
    height: 80%;
    background-color: #000;
    border-radius: 20px;
    display: flex;
    text-align: center;
    flex-direction: column;
}

.lekerdezes p{
    font-family: 'Russo', sans-serif !important;
    color: #fff;
    padding: 0px 20px 20px;
    letter-spacing: 2px;
}

.lekerdezes h2{
    font-family: 'Russo', sans-serif !important;
    color: #fff;

}

.card {
    position: relative;
    width: 95%;
    height: 75%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 2px solid black;
  }
  
  .card-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
  }
  
  .card-content {
    padding: 0px 12px 6px 12px;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    font-family: 'Russo', sans-serif !important;
  }
  
  .card-description {
    margin-bottom: 0px;
    font-size: 14px;
    font-family: 'Russo', sans-serif !important;
  }
  
  .card-button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 6px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 0 0 6px 6px;
    cursor: pointer;
    font-size: 14px;
    font-family: 'Russo', sans-serif !important;
  }
  
  .card-button:hover{
    background-color: #fff;
    color: #000;
    border-top: 2px solid black;
  }
  










  /* 40% rész */
  .right-column {
    width: 40%;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .screenshot{
    position: absolute;
    height: 80%;

  }

  .phone{
    position: absolute;
    height: 100%;
    
  }


