:root {
  --background-light: #2e2e2e;
  --background-darker: #1c1c1c;
  --red-light: #960000;
  --red-darker: #800000;
  --image-shadow: drop-shadow(5px 5px 5px #000000);
  --button-light: #d2d2d2;
  --button-dark: #494949;
}

.home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 90vh !important; /* Ensure it takes full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Hide any overflow */
  background-color: black; 
  border: 5px black solid ;

  }

  .center-image {
    display: block;
    max-width: 90%;
    max-height: 90%;
    margin: 0 auto;
    
  }

  .center-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: 1; /* Ensure video is below text overlay but above background */
  }
  .text-overlay {
    position: absolute;
    top: 20%;
    left: 20%;
    padding: 0px;
  }

  .text-overlay-tb{

    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .Title{
    color: black;
    font-size: 64px;
    font-family: 'Russo', sans-serif !important;
  }

  .cta-button {
    background-color: #9A031E;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    margin-top: 0px;
    transition: background-color 0.3s ease;
    font-family: 'Russo', sans-serif !important;
  }

  .cta-button:hover{
    background-color: #FFF;
    color: #9A031E;
    border: 2px solid #9A031E;
  }

  /* @media lekérdezés a mobil nézethez */
@media (max-width: 767px) {

.home-page {
  margin-top: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 50vh !important; /* Ensure it takes 60% of viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Hide any overflow */
  background-color: black;
  border: 5px solid black;
}

.home-page-container {
  display: grid;
  grid-template-rows: 10% 20% auto !important; /* Text in row 1, button in row 2, video in row 3 */
  justify-items: center;
  align-items: center;
  height: 100%; /* Fill parent height */
  width: 100%; /* Fill parent width */
  background-color: white;
  overflow: hidden; /* Hide overflow */
}



.title-overlay {
  margin-top: 10vh;
  text-align: center;
  grid-row: 0;
}

.Title {

  font-size: 32px; /* Adjust title size */
  margin: 0; /* Remove default margin */
}


.center-video {
  margin-top: 9vh;
  grid-row: 2;
  width: 90%; /* Responsive width */
  max-height: 45%; /* Prevent overflow */
  object-fit: cover; /* Fill space while maintaining aspect ratio */
  border: 3px solid #000; /* Add a black border */
  border-radius: 2%; /* Optional: Add rounded corners */
}


.text-overlay-tb {
  margin-top: 10px;
  text-align: center;
}

.cta-button {
  margin-top: 10vh;
  grid-row: 1;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #9A031E; /* Adjust as needed */
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3; /* Darker on hover */
}



}