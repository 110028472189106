.contact_footer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150vh;
    background-color: #222;
    color: white;
    font-family: Arial, sans-serif;
}

.contact_container {
    width: 90%;
    padding: 20px;
    background: #333;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: -20vh;
}

.contact-header {
    margin-bottom: 30px;
}

.contact-title {
  all:unset;
  background-color: white;
  border-radius: 20px;
  padding: 20px 140px;
  margin: 4%;
  overflow: hidden;
  font-size: 24px !important;
  color: #b30000 !important;
}

.contact_container input,
.contact_container textarea,
.contact_container button {
    width: 70%;
    padding: 12px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
}

.contact_container input,
.contact_container textarea {
    background: #fff;
    color: #000;
}

.contact_container button {
    background: #b30000;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.contact_container button:hover {
    background: #ff0000;
}

.footer{
  width: 93%;
  height: 15vh;
}

.footer_top{
    padding: 0;
}


.datepicker-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.datepicker {
margin-top: -10px !important;
  width: 420px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: 0.3s;
}

.datepicker:focus {
  outline: none;
  border-color: #960000;
  box-shadow: 0 0 5px #960000;
}

/* React-datepicker stílusok */
.react-datepicker {
  height: 400px;
  font-family: 'Russo', sans-serif;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Naptár fejléc */
.react-datepicker__header {
  background-color:  #600000;
  color: white;
  border-bottom: 1px solid black ;
  padding: 10px;
  height: 120px;
}

.react-datepicker__header__dropdown{
    margin-top: 10px;
    color: white;
}

.react-datepicker__year-dropdown-container{
    color: black;
    background-color: #600000;
}

.react-datepicker__year-read-view--selected-year{
    color: white;
}

.react-datepicker__header h2{
    margin-top: 15px;
    color: white !important;
}

.react-datepicker__day-name{
    padding: 8px;
    color: white;
}

/* Napok stílusa */
.react-datepicker__day {
    
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.react-datepicker__day:hover {
  background-color: #960000;
  color: white;
}

/* Kiválasztott nap */
.react-datepicker__day--selected {
  background-color: #800000 !important;
  color: white !important;
}

/* Mai dátum */
.react-datepicker__day--today {
  font-weight: bold;
  color: #800000;
}

/* Tiltott napok */
.react-datepicker__day--disabled {
  color: #ccc;
  cursor: not-allowed;
}

/* Navigációs gombok */
.react-datepicker__navigation {
  top: 10px;
}

.timePicker{
    justify-self: center;
    width: 15% !important;
    color: black;
}

/* Az időválasztó ikon színezése */
.timePicker::-webkit-calendar-picker-indicator {
    cursor: pointer;
}




/* Amikor az input aktív (megnyílik a time picker) */
.timePicker:focus,
.timePicker:active {
    background-color: #800000;
    border-color: #960000;
    box-shadow: 0 0 5px #ff0000;
}

.button-send{
background-color: #960000;
  color: #fff;
  border: 2px solid rgb(0, 0, 0);
  font-size: large !important;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  font-family: 'Russo', sans-serif !important;
  height: 10% !important;
  width: 40% !important;
}

.button.send:hover {
  background-color: #FFF;
  color: #000;
}

.react-datepicker button{
    width: 40px;
    margin: 10px;
}

.react-datepicker__close-icon {
    display: none !important;
}

.switch-container{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-container {
  text-align: center;
}

.loading-image {
  width: 100px;  /* Adjust size as needed */
  height: 100px;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.info_paragraph{
    font-family: 'Russo', sans-serif;
    margin-top: 10px;
    font-size: large;
}



@media only screen and (max-width: 767px) {
   .contact_footer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 155vh;
    background-color: #222;
    color: white;
    
    }

    .contact_right-column{
        height: 120vh;
        all:unset;
        background-color: none;
        margin-top: 20vh;
    }

    .contact_container {
        height: 120vh;
        width: 90%;
        padding: 20px;
        background: #333;
        text-align: center;
        display: flex;
        justify-content: center;
        
    }

    .contact-header {
        margin-bottom: 10px;
    }

    .contact-title {
    all:unset;
    background-color: white;
    border-radius: 12px;
    padding: 12px 10px;
    margin: 4%;
    
    font-size: 18px !important;
    color: #b30000 !important;
    }

    .contact_container input,
    .contact_container textarea,
    .contact_container button {
        width: 70%;
        padding: 12px;
        margin-top: 20px;
        border: none;
        border-radius: 5px;
    }

    .contact_container input,
    .contact_container textarea {
        background: #fff;
        color: #000;
    }

    .contact_container button {
        background: #b30000;
        color: white;
        font-weight: bold;
        cursor: pointer;
    }

    .contact_container button:hover {
        background: #ff0000;
    }

    .footer{
    width: 90%;
    height: 30vh;
    }

    .footer_top{
        padding: 0;
    }

input[type="text"]{
    font-size: 12px;
}

    .datepicker-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    }

    .datepicker {
    margin-top: -10px !important;
    width: 420px;
    padding: 10px;
    font-size: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
    transition: 0.3s;
    }

    .datepicker:focus {
    outline: none;
    border-color: #960000;
    box-shadow: 0 0 5px #960000;
    }

    /* React-datepicker stílusok */
    .react-datepicker {
    height: 400px;
    font-family: 'Russo', sans-serif;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    /* Naptár fejléc */
    .react-datepicker__header {
    background-color:  #600000;
    color: white;
    border-bottom: 1px solid black ;
    padding: 10px;
    height: 120px;
    }

    .react-datepicker__header__dropdown{
        margin-top: 10px;
        color: white;
    }

    .react-datepicker__year-dropdown-container{
        color: black;
        background-color: #600000;
    }

    .react-datepicker__year-read-view--selected-year{
        color: white;
    }

    .react-datepicker__header h2{
        margin-top: 15px;
        color: white !important;
    }

    .react-datepicker__day-name{
        padding: 8px;
        color: white;
    }

    /* Napok stílusa */
    .react-datepicker__day {
        
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    }

    .react-datepicker__day:hover {
    background-color: #960000;
    color: white;
    }

    /* Kiválasztott nap */
    .react-datepicker__day--selected {
    background-color: #800000 !important;
    color: white !important;
    }

    /* Mai dátum */
    .react-datepicker__day--today {
    font-weight: bold;
    color: #800000;
    }

    /* Tiltott napok */
    .react-datepicker__day--disabled {
    color: #ccc;
    cursor: not-allowed;
    }

    /* Navigációs gombok */
    .react-datepicker__navigation {
    top: 10px;
    }

    .timePicker{
        justify-self: center;
        width: 35% !important;
        color: black;
    }

    /* Az időválasztó ikon színezése */
    .timePicker::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }




    /* Amikor az input aktív (megnyílik a time picker) */
    .timePicker:focus,
    .timePicker:active {
        background-color: #800000;
        border-color: #960000;
        box-shadow: 0 0 5px #ff0000;
    }

    .button-send{
    background-color: #960000;
    color: #fff;
    border: 2px solid rgb(0, 0, 0);
    font-size: large !important;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
    font-family: 'Russo', sans-serif !important;
    height: 10% !important;
    width: 40% !important;
    }

    .button.send:hover {
    background-color: #FFF;
    color: #000;
    }

    .react-datepicker button{
        width: 40px;
        margin: 10px;
    }

    .react-datepicker__close-icon {
        display: none !important;
    }

    .switch-container{
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .loading-container {
    text-align: center;
    }

    .loading-image {
    width: 100px;  /* Adjust size as needed */
    height: 100px;
    animation: rotate 2s linear infinite;
    }

    @keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 
    
}