:root {
    --background-light: #2e2e2e;
    --background-darker: #1c1c1c;
    --red-light: #960000;
    --red-darker: #800000;
    --image-shadow: drop-shadow(5px 5px 5px #000000);
    --button-light: #d2d2d2;
    --button-dark: #494949;
  }
  

.welding-section {
    display: flex;
    height: 100vh; /* A szekció magassága a képernyő teljes magasságához igazodik */
    background-color: var(--background-light);
    align-items: center;
    justify-content: space-between;
    padding: 2vh; /* Arányos padding a képernyő magasságához */
    border: 5px black solid;

}

.welding-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 70%;
    padding-top: 5vh; /* Arányos padding a tetején */
    color: black;
    text-align: center;
    background: none !important;
    padding: 2%;
}

.welding-text-h2 {
    font-family: 'Russo', sans-serif;
    font-size: 5vh; /* Betűméret arányosítása a képernyő magasságához */
    margin: 0;
    border-radius: 20px; /* Lekerekítés arányosítása */
    box-shadow: 0.2vh 0.2vh 1vh rgba(0, 0, 0, 0.5);
    padding: 2vh 4vh; /* Csökkentett padding */
    background: white;
    color: var(--red-darker);
    border: #000 2px solid;

}

.description-container {
    font-family: 'Russo', sans-serif;
    font-size: 2vh; /* Betűméret arányosítása a képernyő magasságához */
    margin: 1vh 0; /* Arányos margó a bekezdések között */
    line-height: 1.5;
    text-align: justify;
    background:white;
    border-radius: 20px;
    border: black 2px solid;
    padding: 3vh;
    box-shadow: 0.2vh 0.2vh 1vh rgba(0, 0, 0, 0.5);

}

.description-container-p{
    font-size: 0.9vw;
}

.welding-image {
    position: relative;
    width: 50%;
    height: auto;
    margin-top: -25% !important;
}

.welding-image img {
    position: absolute;
    width: 95%;
    height: auto;
    object-fit: contain;
    border-radius: 20px;
    -webkit-filter: var(--image-shadow);
    filter: var(--image-shadow);
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

.welding-image img.fade-in {
    opacity: 1; /* Fade-in */
}

.welding-image img.fade-out {
    opacity: 0; /* Fade-out */
}


/* @media lekérdezés a mobil nézethez */
@media (max-width: 767px) {
    .welding-section-mobile {
        display: flex;
        height: 115vh; /* A szekció magassága a képernyő teljes magasságához igazodik */
        background-color: white;
        width: 100%;
        justify-content: space-between;
        padding: 0vh; /* Arányos padding a képernyő magasságához */
        border: 5px black solid ;

        
    }
    
    .welding-text-mobile {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        z-index: 1;
        color: black;
        text-align: center;
    }
    
    .welding-title-mobile {
        font-family: 'Russo', sans-serif !important;
        color: #960000;
        background-color: white;
        border-radius: 15px;
        border: 2px black solid ;
        text-align: center;
        margin: 0;
        margin-top:6vh;
            grid-row: 1; /* Adjusted to 1 (grid rows start at 1) */
        font-size: 3vh !important;
        height: 8vh !important;
        width: 80vw;
            /* Center vertically and horizontally */
        display: flex;
        justify-content: center; /* Horizontal alignment */
        align-items: center; /* Vertical alignment */
        font-family: 'Russo', sans-serif !important;
        margin-left: 5%;
    }
    
    .description-mobile {
        font-family: 'Russo', sans-serif;
        font-size: 100%; /* Betűméret arányosítása a képernyő magasságához */
        margin: 1vh 0; /* Arányos margó a bekezdések között */
        line-height: 1.5;
        max-width: 100%;
        text-align: justify;
        padding: 2vh;
        z-index: 1;
      
    }
    
    .welding-image {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .welding-image img {
        width: 80%; /* A kép szélességét a rendelkezésre álló hely 90%-ára csökkentjük */
        height: auto; /* A magasság automatikusan igazodik a szélességhez */
        object-fit: contain; /* A kép arányainak megtartása torzítás nélkül */
        border-radius: 0;
    }
    
  }